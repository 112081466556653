import React, { Component } from 'react';
import NormalInput from '../../components/UI/NormalInput';


const Paymentform = props =>  {

        const {address} = props;
     

        return (
            <div className="login-form left-form">                      
            <form onSubmit={props.addressSubmitHandler} >
              <div className="row">
                <div className="col-lg-12">
                  <div className="form-group">
                  <NormalInput  name="fullName"   value={address.fullName} placeholder={'Full Name'} inputHandler={props.inputHandler}  type="text" required />
                  </div>
                </div>
                <div className="col-lg-6">
                <div className="form-group">
                <NormalInput  name="mobileNumber"  value={address.mobileNumber} placeholder={'Mobile Number'} inputHandler={props.inputHandler}  type="text"  required/>
                </div>
                </div>
                <div className="col-lg-6">
                <div className="form-group">
                <NormalInput    name="amount" value={address.amount}   placeholder={'Amount'} inputHandler={props.inputHandler} type="text"   required  />
                </div>
                </div>
                <div className="col-lg-12">
                <div className="form-group">
                <NormalInput  name="address" value={address.address} placeholder={'Address'}  inputHandler={props.inputHandler}  type="textarea" required />
                </div>
                </div>
                <div className="col-lg-12">
                <div className="form-group">
                <NormalInput  name="whydonate" value={address.whydonate} placeholder={'Why You Donate ??(Optional)'}   inputHandler={props.inputHandler} type="textarea" required/>
                </div>
                </div>
              <div className="col-lg-12">
                  <div className="send-btn">
                    <button type="submit" className="default-btn-one">Save</button>
                  </div>
                </div> 
  
              </div>
            </form>
          </div>
           
        );
    
    
}

export default Paymentform;