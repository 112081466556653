import React, { Component } from 'react'

export class servicepolicy extends Component {
    render() {
        return (
            <div>
                <section className="page-header">
                        {/* /.page-header__bg */}
                        <div className="container">
                        <div className="col-lg-12 text-center">
                            <h2>Service Policy</h2>
                        </div>
                        </div>{/* /.container */}
                    </section>{/* /.page-header */}
            </div>
        )
    }
}

export default servicepolicy
