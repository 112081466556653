import React, { Component } from 'react'
import {Link,Redirect} from 'react-router-dom';
import * as authActions from '../store/actions/authActions';
import swal from 'sweetalert';
import { connect } from 'react-redux';
export class Donation extends Component {
    componentDidMount() {
        if(!this.props.auth.isAuthenticated){
            this.props.getToken()
            .then(result => {
                // result will be either true or false
                if(result){
                    this.setState({
                        redirectToReferrer: true
                    });
                }
                
            })
      
        }
    }

    render() {
    
        let  userdonation =<Link to="/login"  target="_blank" className="thm-btn-dynamic-radius  book-btn2" data-target="#myModal" >Donate Now
        </Link>
        if(this.props.auth.isAuthenticated){
                       
            userdonation = <div>
             <Link to="/paydonate"  target="_blank" className="thm-btn-dynamic-radius  book-btn2" data-target="#myModal" >Donate Now
            </Link>
              </div>   
                     
            }
        return (
            <div>
                <section className="page-header">
                    {/* /.page-header__bg */}
                    <div className="container">
                    <div className="col-lg-12 text-center">
                        <h2>Donations</h2>
                    </div>
                    </div>{/* /.container */}
                </section>{/* /.page-header */}
                <section className="section video-card-one pt-40 pb-40" style={{background: '#f2ecec'}}>
                    <div className="container">
                    <div className="col-lg-12 col-xs-12 sp">
                        <p>Gurdwara Baba Vadbhag Singh Ji is a non-political, non-profit making, religious charitable organisation. We are reliant upon donations to keep the Gurdwara Sahib running smoothly so that we can provide our congregation the best possible experience upon each visit. If you would like to make a donation or set up a standing order, <br />you can find out more by clicking on the link below.</p>
                    </div>{/* /.col-lg-5 */}
                    </div>{/* /.row */}
                </section>{/* /.video-card-two */}
                <section className="video-card-one pt-40 pb-40">
                    <div className="container">
                    <div className="col-lg-12 col-xs-12 sp">
                        <h3>Donate Online</h3>
                        <p>You can donate to us online through our secure payment gateway. </p>
                      {userdonation}
                       
                    </div>{/* /.col-lg-5 */}
                    </div>{/* /.row */}
                </section>{/* /.video-card-two */}
                <section className="video-card-one pt-40 pb-40" style={{background: '#f2ecec'}}>
                    <div className="container">
                    <div className="col-lg-12 col-xs-12 sp">
                        <h3 style={{color: '#f15c22'}}>Standing Order</h3>
                        <p>If you wish to set up a standing order, please complete the standing order form and return it to us for onward submission to your bank or building society. Should you require any assistance, we would be more than happy to help.</p><br />
                        <p>The form contains a Gift Aid Declaration section for those individuals who are UK taxpayers. Gift Aid is a scheme which increases the value of donations by 25% and it won’t cost the donor anything extra. Please read the guidance on the form carefully before completing.</p><br />
                        <p>Please note that you can set up a standing order through your online banking by using our bank account details. We would be grateful if you could also fill in the gift aid declaration of the standing order form and return it to us. This will allow us to claim gift aid for your standing order donations.</p>
                        <a href="assets/images/causes/gurudwara.pdf" className="thm-btn-dynamic-radius book-btn2" target="_blank">Standing Order Form
                        </a>{/* /.thm-btn dynamic-radius */}
                    </div>{/* /.col-lg-5 */}
                    </div>{/* /.row */}
                </section>{/* /.video-card-two */}
                <section className="causes-page pt-120 pb-120">
                    <div className="container">
                    <div className="row">
                        <div className="col-lg-6">
                        <div className="cause-card">
                            <div className="cause-card__inner">
                            <div className="cause-card__content">
                                <h3><a href="#">Bank Transfer</a></h3>
                                <p>For direct bank transfers, please use the bank account details below:-</p>
                                <p>BARCLAYS BANK ,<br />Gurdwara Baba Vadbhag Singh Ji<br />
                                Sort Code: 20-23-55<br />
                                Account No: 03204693</p>
                                <p>Donating from abroad?Please use these additional details:<br />
                                SWIFTBIC: BUKBGB22<br />
                                IBAN: GB96 BUKB 2023 5503 2046 93</p>
                            </div>{/* /.cause-card__content */}
                            </div>{/* /.cause-card__inner */}
                        </div>{/* /.cause-card */}
                        </div>
                        <div className="col-lg-6">
                        <div className="cause-card">
                            <div className="cause-card__inner">
                            <div className="cause-card__content">
                                <h3><a href="#">Cheque</a></h3>
                                <p>Cheques should be made payable to Gurdwara Baba Vadbhag Singh Ji and posted to the following address:-</p><br /><br />
                                <p>
                                Gurdwara Baba Vadbhag Singh Ji<br />
                                6 Bayton Road<br />
                                Exhall<br />
                                Coventry<br />
                                CV7 9EJ
                                <br />
                                <br/>
                                </p>
                            </div>{/* /.cause-card__content */}
                            </div>{/* /.cause-card__inner */}
                        </div>{/* /.cause-card */}
                        </div>{/* /.causes-col__3 */}
                    </div>
                    </div>
                </section>
                </div>
        )
    }
}
const mapDispatchToProps = dispatch => {
    return {
        authenticate: (email, password) => dispatch(authActions.authenticate(email, password)),
        getToken: () => dispatch(authActions.getToken())
    }
  }
  const mapStateToProps = state => {
    return {
        auth: state.auth
    }
  }
export default connect(mapStateToProps, mapDispatchToProps)(Donation);
