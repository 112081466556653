import React, { Component } from 'react';
import Header from '../include/Header';
import * as authActions from '../store/actions/authActions';
import { connect } from 'react-redux';
import { base_url } from '../constants';
import Paypal from '../Pages/Paypal';

export class Bookcart extends Component {
    state = {
        eventList: [],
        order: [],
        isOrderConfirm: false,
        selectedPaymentType: 'paypal',
        paymentTypes: [
            {id: 1, value: 'paypal', label: 'Paypal', isActive: true},
        ],

        
    }

  
    componentWillMount(){
        if(!this.props.auth.isAuthenticated){
            this.props.getToken()
            .then(result => {
                if(result){
                    this.getEvent();
                }else{
                    this.props.history.push('/login');
                }
            })
        }else{
            this.getEvent();
        }

        
    }

    getEvent = () => {
        console.log(this.props.auth.isAuthenticated)
        const token =  this.props.auth.token;
        const userId = this.props.auth.user.userId;
        fetch(`${base_url}/book/bookuser/${userId}`, {
            headers: {
                'Content-Type': 'application/json',
                'auth-token': token
            }
        })        
        .then(response => response.json())
        .then(jsonResponse => {            
             this.setState({
                eventList: jsonResponse.message.cart   
                         
            });
           
        })
        
        .catch(error => {
            console.log(error);
        })     
        
    }
    selectPaymentOption = (e) => {
        this.setState({
            selectedPaymentType: e.target.value
        })
    }
    transactionSuccess = async () => {

        if(this.state.selectedPaymentType !== 'paypal'){
            return;
        }
        

        const order = this.state.eventList.map((item ,value)=> {
            return {
                product: item.product,
                price: item.price,
                name :item.name,
                image :item.image,
                eventdate:item.eventdate
            }
        });
        console.log(order );
        try{
      
            const response = await fetch(`${base_url}/bookorder/createbook`,{
                headers: {
                    'Content-Type': 'application/json',
                    'auth-token': this.props.auth.token
                },
                body: JSON.stringify({
                    user: this.props.auth.user.userId,
                    username:this.props.auth.user.name,
                    order: order,
                    paymentType: 'paypal',
                    paymentStatus: 'success',
                }),
                method: 'POST'
            });

            const jsonResponse = await response.json();
            if(response.status === 201){
                this.props.history.push({
                    pathname: '/book-thank',
                    search: '?orderid='+jsonResponse.message._id,
                    state: jsonResponse.message
                });
            }

        }catch(error){
            console.log(error);
        }
        
    }
    transactionError = () => {
        console.log('Payment unsuccessfull');
     }
   
 
     transactionCanceled = () => {
         console.log('Transaction canceled')
     }
 
     formatDate = (date) => {
        let d = new Date(date);
        return `${d.getDate()}-${d.getMonth() + 1}-${d.getFullYear()}`;
      }
    render() {
        let sum_total = 0 ;
        var audienses = this.state.eventList.map((value, index) => (
            sum_total = parseInt(sum_total) + parseInt(value.total),
                <tr>
                     <td>
                      <div className="img-product">
                      <img style={{  width: '100%' }} src={`${base_url}/eventImages/`+ value.image} />
                         </div>
                      
                     </td>
                     <td class="name-product">
                     {value.name}<br></br>
                     </td>
                     <td class="name-product">
                     {this.formatDate(value.eventdate)}<br></br>
                     </td>
                     <td>
                       <div className="total">
                       £{value.total}
                       </div>
                     </td>
     </tr>
              
         
                      ));
     
                     
       
        return (  
            <div className="cart-wrap">
            <div className="container">
            <div className="row">
            <div className="col-lg-8">
            <div className="Card">
                            <p className="CardText">Login {this.props.auth.isAuthenticated ? <i className="fas fa-check"></i> : null}</p>
                            <p className="CardText">Email: {this.props.auth.user.email}</p>
                        </div>
            
            <div className="table-cart">
                  <table className="table-view">
                    <thead>
                      <tr>
                        <th >Event image</th>
                        <th >Event Name</th>
                        <th >Date</th>
                        <th >Price</th>
                     
                        
                      </tr>
                    </thead>
                    {audienses} 
                    <tr><td colspan="4"style={{textAlign: 'right'}}>£{sum_total }</td></tr> 
                    </table>
                </div>
             </div>
         
             <div  class="col-lg-4">
                <div class="cart-totals">
                          <h3>Your Booking Amount..
                              Click Here To Pay..
                          </h3>
                          <Paypal  toPay={parseInt(sum_total)}  onSuccess={this.transactionSuccess} transactionError={this.transactionError}  transactionCanceled={this.transactionCanceled}/>     
                 </div>
                </div> 
          </div>
          </div>
      </div>
                   
        )
    }
}
const mapStateToProps = state => {
    return {
        auth: state.auth,
      
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getToken: () => dispatch(authActions.getToken())
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(Bookcart);
