import React, { Component } from 'react'

export class Termscondition extends Component {
    render() {
        return (
            <div>
  <section className="page-header">
    {/* /.page-header__bg */}
    <div className="container">
      <div className="col-lg-12 text-center">
        <h2>Terms &amp; conditions</h2>
      </div>
    </div>{/* /.container */}
  </section>{/* /.page-header */}
  <section className="video-card-one pt-60 pb-60" style={{background: '#f2ecec'}}>
    <div className="container">
      <div className="col-lg-12 col-xs-12">
        <h3>Acknowledgment and acceptance of Terms and Conditions</h3>
        <p>Please note that by accessing, using or browsing this website you agree to be bound by its terms, conditions, disclaimers and limitations of liability (“Terms and Conditions”). gurdwarabvs.org reserves the right to amend or update such terms, conditions, disclaimers and limitations of liability at any time without providing notice to you. By using the website, you acknowledge that you have read and understood these Terms and Conditions.</p>
        <h3 style={{color: '#f15c22'}}>Images and Pricing</h3>
        <p>Please note that whilst every effort is made to accurately portray the items shown on the Gurdwara Baba Vadbhag Singh Ji’s website, variations may occur. All images represent an accurate portrayal of its stock at time of publishing but are subject to change without notice. All care is taken to ensure that pricing is current and accurate at time of upload, however we take no responsibility for typographical errors. Any incorrect pricing will be immediately corrected upon notification.</p>
        <p>In cases where the product you ordered is then discounted through a sale event (after your purchase) we cannot refund the difference between your original order price, and the new discounted price.</p>
        <h3>Product description</h3>
        <p>gurdwarabvs.org and its associates attempt to be as accurate as possible. However, gurdwarabvs.org does not warrant that product descriptions or other content of this site is accurate, complete, reliable, current, or error-free. If a product offered by gurdwarabvs.org itself is not as described, your sole remedy is notify Gurdwara Baba Vadbhag Singh Ji within 24 hours from receiving the product and return it in unused condition. If for some other reason the customer would like to return the product the customer is obligated to notify Gurdwara Baba Vadbhag Singh Ji within 5 working days. </p>
      </div>{/* /.col-lg-5 */}
    </div>{/* /.row */}
  </section>{/* /.video-card-two */}
  <section className="video-card-one pt-60 pb-60">
    <div className="container">
      <div className="col-lg-12 col-xs-12">
        <h3 style={{color: '#f15c22'}}>All information supplied has NO WARRANTY whatsoever </h3>
        <p>Information and statements are not intended to diagnose, treat, cure, or prevent any disease. No warranty is made that any information on or linked to this site is complete and/or accurate. We are not nor do we claim to be health care professionals, therefore please consult your doctor before taking any supplements, especially if you have any existing medical conditions. Gurdwara Baba Vadbhag Singh Ji will not be liable for any outcomes, loss and damages from using any information or products whatsoever on or linked to this website.</p>
        <h3>Children</h3>
        <p>gurdwarabvs.org does not sell products for purchase by children. We may sell children’s products for purchase by adults. If you are under 18, you may use gurdwarabvs.org only with the involvement of a parent or guardian.</p>
        <h3>Our right of cancellation</h3>
        <p>
          If for reasons beyond our reasonable control, including but not limited to an inability or failure on the part of the manufacturers or suppliers of the goods to supply the goods to us, we are unable to supply the goods to you, we may cancel the agreement at any time before the goods are delivered by giving notice to you. We shall promptly repay to you any sums paid by you or on your behalf under or in relation the agreement. We shall not be liable for any other loss or damage whatever arising from such cancellation.
        </p>
      </div>{/* /.col-lg-5 */}
    </div>{/* /.row */}
  </section>{/* /.video-card-two */}
  <section className="video-card-one pt-60 pb-60" style={{background: '#f2ecec'}}>
    <div className="container">
      <div className="col-lg-12 col-xs-12">
        <h3>Return Policy</h3>
        <h3>Please read carefully</h3>
        <p>Gurdwara Baba Vadbhag Singh Ji are collaborating with Printful to print and deliver the items from our store. We design the products for the store and Printful, makes and delivers them. Our “Return Policy” is based on Printful’s “Return Policy”. We are unfortunately unable to provide returns, refunds or exchanges, unless a product is damaged (as reviewed by Printful). </p><br />
        <p> We only replace (exchange) items if they are defective or damaged. To be eligible for an exchange of a damaged good, clear pictures of the item must be taken and emailed to info@gurdwarabvs.org and will be reviewed by Printful. We will send a return merchandise authorization (RMA) number to be exchanged with the same item. </p><br />
        <p>ALL SALES ARE FINAL as each order placed is custom made for every single customer after the order is received. We do not accept returns or replacements in the case where the customer has buyer’s remorse, needs a different color, or a smaller or larger size. (You can view the sizing chart on each of the product pages and please don’t hesitate to contact us with any further questions before ordering.)</p><br />
        <p>
          Once you have clicked on the “complete order” button, unfortunately it is not possible to edit or cancel your order. However, if you need to change some parameters, like addresses, etc., please contact us via email (info@gurdwarabvs.org) as soon as possible. We are not bound to make such modifications in your order, but we will do our best on a case-by-case basis.
        </p>
      </div>{/* /.col-lg-5 */}
    </div>{/* /.row */}
  </section>{/* /.video-card-two */}
  <section className="video-card-one pt-60 pb-60">
    <div className="container">
      <div className="col-lg-12 col-xs-12">
        <h3>Shipping Policy</h3>
        <h3>Estimated shipping delivery times</h3>
        <p>USA: 3-5 business days<br />
          Canada: 5-10 business days<br />
          World: 10-20 business days<br />
          Your order will be sent out on average within 3 days of ordering. </p>
        <h3 style={{color: '#f15c22'}}>International shipping</h3>
        <p>International shipments may incur customs fees. For each country the customs policy is different, and the fee is usually based on a variety of factors like weight, value, and size. We do not take responsibility for customs fees. </p><br />
        <p>The customer pays all shipping, insurance and customs fees, and assumes responsibility for any lost shipments to countries that don’t allow us to insure shipments within their borders.</p>
        <h3>Reason for return</h3>
        <p>Wrong Address – If you provide an address that is considered insufficient by the courier, the shipment will be returned to the Printful headquarters. You will be liable for reshipment costs once we have confirmed an updated address with you. </p><br />
        <p>Unclaimed – Shipments that go unclaimed are returned to the Printful headquarters and you will be liable for the cost of a reshipment to you.</p>
        <h3 style={{color: '#f15c22'}}>
          Damaged items
        </h3>
        <p>
          The last thing we want is for you to be stuck with a damaged item. If it arrives to you damaged, then please get in touch with us within a week’s time!
        </p><br />
        <p>Along with Printful, we will review replacement/return requests only:</p>
        <br /><p>(a) if a product is damaged or there is a print error</p><br />
        <p>(b) we receive an email with clear pictures of the damaged item to info@gurdwarabvs.org</p><br />
        <p>We only replace items if they are defective or damaged. Printful do not offer any refund orders for buyer’s remorse, or for size exchanges as each order is custom made for each customer. Therefore, a new order would need to be placed for an updated size.If you need to exchange a defective or damaged item for the same item, send us an email at info@gurdwarabvs.org with pictures to get the return merchandise authorization (RMA) number. </p>
        <br /><p>Please note that you will be responsible for paying for your own shipping costs for returning your item. Shipping costs are non-refundable. If you receive a refund, the cost of return shipping will be deducted from your refund. </p>
      </div>{/* /.col-lg-5 */}
    </div>{/* /.row */}
  </section>{/* /.video-card-two */}
  <section className="video-card-one pt-60 pb-60" style={{background: '#f2ecec'}}>
    <div className="container">
      <div className="col-lg-12 col-xs-12">
        <h3>Lost shipment</h3>
        <p>It is the customer’s responsibility to file any claim with a carrier for a lost shipment if carrier tracking indicates that the product was delivered. Unfortunately, in such case Printful will not make any refunds and will not resend the Product.</p>
        <h3 style={{color: '#f15c22'}}>Order never arrived</h3>
        <p>If your order didn’t end up arriving, be sure to let us know!</p><br />
        <p>First check your shipping confirmation and check that you had entered the correct address. If the address was incorrect, though we’d be happy to send you another order to the correct address, it will have to be at your cost.
          If the shipping address was correct, get in touch with us at info@gurdwarabvs.org noting your order number. We’ll see what can be done. </p>
        <h3>Sale items</h3>
        <p>Only regular priced items may be refunded, unfortunately sale items cannot be refunded.</p>
      </div>{/* /.col-lg-5 */}
    </div>{/* /.row */}
  </section>{/* /.video-card-two */}


                
            </div>
        )
    }
}

export default Termscondition
