import React, { Component } from 'react'
import { base_url } from '../constants';

export class Gallery extends Component {
    state = {
        gallery: []
    }
    
      componentDidMount() {
        fetch(`${base_url}/gallery/fetchgallery`)
        .then((response) => response.json())
        .then(productList => {
            this.setState({ gallery: productList });
        });
    }
    render() {
        return (
            <div>
                                            
                     <section className="page-header">
                                            {/* /.page-header__bg */}
                                            <div className="container">
                                            <div className="col-lg-12 text-center">
                                                <h2>Gallery</h2>
                                            </div>
                                            </div>{/* /.container */}
                    </section>{/* /.page-header */}
                                        <section className="gallery-page pt-80 pb-80">
                                            <div className="container">
                                          <div className="gallery-3-col">

  					  <img width="220" height="250" border="0" align="center"  src="/images/bvs_qrcode.png"/>

					  <a href="https://www.facebook.com/profile.php?id=100093080821106" aria-label="@gurdwarabvs facebook" target="_blank">
  					  	<img width="220" height="250" border="0" align="center"  src="/images/bvs_fb.png"/>
					  </a>
					  <a href="https://tiktok.com/@gurdwarabvs" aria-label="@gurdwarabvs tiktok" target="_blank">
  					  	<img width="220" height="250" border="0" align="center"  src="/images/bvs_tiktok.png"/>
					  </a>
					  <a href="https://instagram.com/gurdwarabvs" aria-label="@gurdwarabvs instagram" target="_blank">
  					  	<img width="220" height="250" border="0" align="center"  src="/images/bvs_insta.png"/>
					  </a>

                                            {this.state.gallery.map((g) => (
                                                <div className="gallery-card">
                                                <img src={`${base_url}/galleryUpload/`+ g.images} className="img-fluid" />
						
                                                <div className="gallery-content">
                                                    <a href={`${base_url}/galleryUpload/`+ g.images} className="img-popup" aria-label="open image" target="_blank"><i className="fal fa-plus" /></a>
                                                </div>
                                                </div> 
                                                  ))} 
                                            </div>
                                          
                                            </div>
                                        </section>
                                    

         
            </div>
        )
    }
}

export default Gallery
