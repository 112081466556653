import React, { Component } from 'react'

export class Wedding extends Component {
    render() {
        return (
            <div>
                  <section className="page-header">
                    {/* /.page-header__bg */}
                    <div className="container">
                    <div className="col-lg-12 text-center">
                        <h2>Sikh Wedding</h2>
                    </div>
                    </div>{/* /.container */}
                </section>{/* /.page-header */}
                <section className="video-card-one  pt-60 pb-60" style={{background: '#f2ecec'}}>
                    <div className="container">
                    <div className="col-lg-12 col-xs-12">
                        <h3>Overview</h3>
                        <p>The Sikh wedding ceremony is known as “Anand Karaj” which means ‘Blissful Union”. The Sikh marriage is a very colourful and unique ceremony in which two individuals are joined in a partnership of two equals.</p><br></br>
                        <p>It is not merely a physical and legal contract but a sacrament – a holy union between two souls. The spiritual goal of any Sikh is to merge his or her soul (atma) with God (Parmatma) and in marriage, the couple vow to help each other towards this goal.</p><br></br>
                        <p>The early part of the day is devoted to the religious ceremony which almost always takes place in a Gurdwara.</p>
                    </div>{/* /.col-lg-5 */}
                    </div>{/* /.row */}
                </section>{/* /.video-card-two */}
       
    <section id="tabs" className="video-card-one  pt-60 pb-60">
	<div class="container">
    <div class="col-xs-12 text-center">
            <h3 classnName="text-center">Arrival at the Gurdwara</h3>
            <p>The wedding day begins with the “Barat”, which is the Groom’s side or the party of people accompanying the groom.</p>
            </div>
		<div class="row">
			<div class="col-xs-12 ">
				<nav style={{paddingTop: 25}}>
					<div class="nav nav-tabs nav-fill" id="nav-tab" role="tablist">
						<a class="nav-item nav-link active" id="nav-home-tab" data-toggle="tab" href="#nav-home" role="tab" aria-controls="nav-home" aria-selected="true">1.Departure of Bharat</a>
						<a class="nav-item nav-link" id="nav-profile-tab" data-toggle="tab" href="#nav-profile" role="tab" aria-controls="nav-profile" aria-selected="false">2.Reception of Bharat</a>
						<a class="nav-item nav-link" id="nav-contact-tab" data-toggle="tab" href="#nav-contact" role="tab" aria-controls="nav-contact" aria-selected="false">3.Milli</a>
						<a class="nav-item nav-link" id="nav-about-tab" data-toggle="tab" href="#nav-about" role="tab" aria-controls="nav-about" aria-selected="false">4.TeaA</a>
					</div>
				</nav>
				<div class="tab-content py-3 px-3 px-sm-0" id="nav-tabContent">
					<div class="tab-pane fade show active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">
                    The Barat will leave from the Groom’s house to go to the Gurdwara usually near the Bride’s house, where the actual wedding will take place. The arrangements for the rest of the day are normally made entirely by the Bride’s family.
					</div>
					<div class="tab-pane fade" id="nav-profile" role="tabpanel" aria-labelledby="nav-profile-tab">
					The Groom’s party is “received” by the Bride’s party at the Gurdwara. This usually takes place at about 9.30am
					</div>
					<div class="tab-pane fade" id="nav-contact" role="tabpanel" aria-labelledby="nav-contact-tab">
					The Ardas will be performed by the priest (Giani) followed by the formal introductions of the families. This will include the bride’s & groom’s father, grandfather and Mama – (mother’s brother). They will formally meet, exchange garlands (and gifts) and have their photographs taken. This normally takes place in the foyer area of the Gurdwara at about 10am.
					</div>
					<div class="tab-pane fade" id="nav-about" role="tabpanel" aria-labelledby="nav-about-tab">
					Tea with savouries (normally samosa, pakoras) and sweets (generally jalebis, ladoos and barfi) will be served and are normally taken standing at tables in the Langar hall at the Gurdwara. Both the Groom’s side and Bride’s sides will take tea in turn and this should be concluded at about 10.30am
					</div>
				</div>
			
			</div>
		</div>
	</div>
</section>
                        
<section id="tabs" className="video-card-one  pt-60 pb-60" style={{background: '#f2ecec'}}>
	<div class="container">
    <div class="col-xs-12 text-center">
            <h3 >Main Ceremony</h3>
            <p>The Anand Karaj is the formal part of the ceremony which takes place after tea, by about 11am, everyone proceeds into the main prayer hall. </p><br></br>
            <p>Once in the main hall, you will be expected to sit on the carpeted floor for at least an hour. It is essential that suitable clothing be worn. The formal part of the wedding ceremony normally starts at about 11am and ends at about 1pm and comprises of the following functions:</p>
            </div>
		<div class="row">
			<div class="col-xs-12 ">
            <nav style={{paddingTop: 25}}>
					<div class="nav nav-tabs nav-fill" id="nav-tab" role="tablist">
						<a class="nav-item nav-link active" id="nav-home1-tab" data-toggle="tab" href="#nav-home1" role="tab" aria-controls="nav-home1" aria-selected="true">1. Kirtan</a>
						<a class="nav-item nav-link" id="nav-profile1-tab" data-toggle="tab" href="#nav-profile1" role="tab" aria-controls="nav-profile1" aria-selected="false">2. Ardas</a>
                        <a class="nav-item nav-link" id="nav-contact1-tab" data-toggle="tab" href="#nav-contact1" role="tab" aria-controls="nav-contact1" aria-selected="false">3. Palla</a>
						<a class="nav-item nav-link" id="nav-about1-tab" data-toggle="tab" href="#nav-about1" role="tab" aria-controls="nav-about1" aria-selected="false">4. Laava</a>
                        <a class="nav-item nav-link" id="nav-contact3-tab" data-toggle="tab" href="#nav-contact3" role="tab" aria-controls="nav-contact3" aria-selected="false">5. Ardas</a>
						<a class="nav-item nav-link" id="nav-about3-tab" data-toggle="tab" href="#nav-about3" role="tab" aria-controls="nav-about3" aria-selected="false">6. Sagaan</a>
                        <a class="nav-item nav-link" id="nav-about4-tab" data-toggle="tab" href="#nav-about4" role="tab" aria-controls="nav-about4" aria-selected="false">7. Kara Parshad</a>

					</div>
				</nav>
				<div class="tab-content py-3 px-3 px-sm-0" id="nav-tabContent">
					<div class="tab-pane fade show active" id="nav-home1" role="tabpanel" aria-labelledby="nav-home1-tab">
                    The singing of the Holy Hymns – Gurbani. As you enter the darbar hall, musical singing of sacred hymns from the holy scriptures will already be taking place. After several Shabads (hymns) have been sang, the priest (Giani) or the stage secretary will announce the timetable for the formal part of the Anand Karaj ceremony in the darbar hall.
					</div>
					<div class="tab-pane fade" id="nav-profile1" role="tabpanel" aria-labelledby="nav-profile1-tab">
                    Key People (Bride, groom, both parents of bride and groom) will stand straight with folded hands and join in this prayer.
					</div>
                    <div class="tab-pane fade" id="nav-contact1" role="tabpanel" aria-labelledby="nav-contact1-tab">
					The bride’s father will formally hand the end of the sash, which the bridegroom wears over his shoulder into the hands of the bride. At the same time the shabad “Palla tanda lagee” is sung by the Kirtania jatha (musicians).
					</div>
					<div class="tab-pane fade" id="nav-about1" role="tabpanel" aria-labelledby="nav-about1-tab">
                    The Bride and Groom will walk in tow around Sri Guru Granth Sahib Ji four times at the set intervals. First the priest will read a Laav (one of four) from the Guru Granth Sahib; then the musicians will sing the same laav while the Bride and Groom will circle the Guru in a clockwise direction.
					</div>
                    <div class="tab-pane fade" id="nav-contact3" role="tabpanel" aria-labelledby="nav-contact3-tab">
                    All the congregation will stand and join in the ardas followed by Hukamnama.
					</div>
					<div class="tab-pane fade" id="nav-about3" role="tabpanel" aria-labelledby="nav-about3-tab">
                    The parents of the Bride and Groom, followed by key people and the congregation will bless the newly wedded and give gifts or money and have photos taken.
					</div>
                    <div class="tab-pane fade" id="nav-about4" role="tabpanel" aria-labelledby="nav-about4-tab">
                     <br></br> Blessed “Sweet pudding” is served. This ends the formal part of the Sikh wedding ceremony
					</div>
				
				</div>
			
			</div>
		</div>
	</div>
</section>
<section className="video-card-one  pt-60 pb-60">
                    <div className="container">
                    <div className="col-lg-12 col-xs-12">
                        <h3>Catering</h3>
                        <p>We can provide langar after the wedding ceremony for those families who would prefer to conclude the wedding at the Gurdwara and not have to worry about securing a commercial venue and third party catering services. We can discuss this in more detail should this be of interest.</p>
                       
                       
                    </div>{/* /.col-lg-5 */}
                    </div>{/* /.row */}
                </section>{/* /.video-card-two */}
                <div className=" login-section ptb-100">
  <div className="container">
    <div className="row">
      <div className=" col-lg-6">
        <div className=" login-form left-form">
          <div className="block-title text-center">
            <h3>Wedding Enquiry</h3>
          </div>                       
          <form >
            <div className="row">
               <div className="col-lg-12">
                <div className="form-group">
                  <input type="text"  name="email"  className="form-control" placeholder="Name" />  
                </div>
              </div>
         
              <div className="col-lg-12">
              <div className="form-group">
              <input  type="text"  className="form-control"  placeholder="Number" />
             </div>
              </div>
              <div className="col-lg-12">
              <div className="form-group">
              <input  type="text"  className="form-control"  placeholder="Email" />
             </div>
              </div>
              <div className="col-lg-12">
              <div className="form-group">
              <textarea name="message" className="form-control" placeholder="Message" />
             </div>
              </div>
              <div className="col-lg-12">
                <div className="send-btn">
                  <button type="submit" className="default-btn-one">Submit</button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
      <div className="col-lg-6">
      
      <div className="video-card-two__box">
                    <img src="assets/images/causes/g.jpg" alt />
                    {/* /.video-card-two__box-btn */}
      </div>{/* /.video-card-two__box */}
      </div>
    </div>
  </div>
</div>
            </div>
        )
    }
}

export default Wedding
