import React, { Component } from 'react';
import NormalInput from '../../components/UI/NormalInput';


const AddressForm = props =>  {

        const {address} = props;
     

        return (
            <div className="login-form left-form">                      
            <form onSubmit={props.addressSubmitHandler} >
              <div className="row">
                <div className="col-lg-12">
                  <div className="form-group">
                  <NormalInput  name="fullName"   value={address.fullName} placeholder={'Full Name'} inputHandler={props.inputHandler}  type="text" required />
                  </div>
                </div>
                <div className="col-lg-6">
                <div className="form-group">
                <NormalInput  name="mobileNumber"  value={address.mobileNumber} placeholder={'Mobile Number'} inputHandler={props.inputHandler}  type="text"  required/>
                </div>
                </div>
                <div className="col-lg-6">
                <div className="form-group">
                <NormalInput    name="alternatePhoneNumber" value={address.alternateNumber}   placeholder={'Alternate Number'} inputHandler={props.inputHandler} type="text"   required  />
                </div>
                </div>
                <div className="col-lg-12">
                <div className="form-group">
                <NormalInput  name="pinCode" value={address.pinCode}  placeholder={'ZipCode'} inputHandler={props.inputHandler}  type="text" required/>
                </div>
                </div>
                <div className="col-lg-12">
                <div className="form-group">
                <NormalInput   name="locality" value={address.locality}  placeholder={'Locality'} inputHandler={props.inputHandler}    type="text"  required />
                </div>
                </div>
                <div className="col-lg-12">
                <div className="form-group">
                <NormalInput    name="address" value={address.address} placeholder={'Address (Area and Street)'}  inputHandler={props.inputHandler}  type="textarea" required />
                </div>
                </div>
                <div className="col-lg-6">
                <div className="form-group">
                <NormalInput  name="cityDistrictTown"   value={address.cityDistrictTown}   placeholder={'City/District/Town'}  inputHandler={props.inputHandler} type="text"   required />
                </div>
                </div>
                <div className="col-lg-6">
                <div className="form-group">
                <NormalInput   name="state" value={address.state}  placeholder={'State'}inputHandler={props.inputHandler}  type="text" required />
                </div>
                </div>
                <div className="col-lg-12">
                <div className="form-group">
                <NormalInput  name="landmark" value={address.landmark} placeholder={'Landmark (Optional)'}   inputHandler={props.inputHandler} type="text" required/>
                </div>
                </div>
              <div className="col-lg-12">
                  <div className="send-btn">
                    <button type="submit" className="default-btn-one">Save Address</button>
                  </div>
                </div> 
  
              </div>
            </form>
          </div>
           
        );
    
    
}

export default AddressForm;