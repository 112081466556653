import React, { Component } from 'react'

export class Provider extends Component {
    render() {
        return (
            <div>
                    <section className="page-header">
                    {/* /.page-header__bg */}
                    <div className="container">
                    <div className="col-lg-12 text-center">
                        <h2>Providers</h2>
                    </div>
                    </div>{/* /.container */}
                </section>{/* /.page-header */}
            </div>
        )
    }
}

export default Provider
