import React, { Component } from 'react'
import { base_url } from '../constants';
import * as authActions from '../store/actions/authActions';
import * as bookActions from '../store/actions/bookActions';
import { connect } from 'react-redux';
import swal from 'sweetalert';
import {  Redirect } from 'react-router-dom';

export class Eventdetails extends Component {
  state = {
    event:null,
    redirectToReferrer: false
}
componentDidMount() {
  if(!this.props.auth.isAuthenticated){
    this.props.getToken()
    .then(result => {
        if(result){
            this.props.getbookItems(this.props.auth.token, this.props.auth.user.userId)
            .then(response => {
                console.log(response);
            })
            .catch(error => {
                console.log(error);
            })
        }
    })
    .catch(error => {
        console.log(error);
    })
}
  const { eventId } = this.props.match.params;
  fetch(`${base_url}/event/${eventId}`)
  .then(response => response.json())
  .then(jsonResponse => {

      if(jsonResponse.hasOwnProperty('message')){
          console.log(jsonResponse);
          this.setState({
              event: jsonResponse.message
          })
      }
  })
  .catch(error => {
      console.log(error);
  });

}
addToBook = (eventId, final_price, eventtitle,images,eventdate) => {

  if(!this.props.auth.isAuthenticated){
      this.props.history.push('/login');
      return;
  }

  const { auth } = this.props;
  const cartItem = {
      user: auth.user.userId,
      username: auth.user.name,
      product: eventId,
      name: eventtitle,
      price: final_price,
      image: images,
      eventdate:eventdate
  }
  console.log(cartItem)
  this.props.addToBook(auth.token, cartItem)
  .then(response => {
      console.log(response);
    if(response){
        this.setState({
            redirectToReferrer: true
        });
        swal({
          title: "Done!",
          text: "EventBook Success",
          icon: "success",
          timer: 1000,
          button: false
        })
     }
  })
  .catch(error => {
      console.log(error);
  });
}

formatDate = (date) => {
  let d = new Date(date);
  return `${d.getDate()}/${d.getMonth() + 1}/${d.getFullYear()}`;
}
  render() {
         
    if(this.state.redirectToReferrer){
      return <Redirect to="/book" />
  }
    const { event } = this.state;

        let eventDescription;
        let final_price =0;

        if(this.state.event){
          eventDescription=
            <div>
          <section className="page-header">
              <div className="container">
                <div className="col-lg-12 text-center">
                  <h2>Event Details</h2>
                </div>
              </div>
          </section>
           <div className="productpage">
            <div className="container">
            <div className="card p-details">
              <div className="container-fliud">
                <div className="row">
                <div className="preview col-md-4">
                          <img style={{  width: '100%' }} src={`${base_url}/eventImages/`+ event.images} />
                 </div>
                  <div className="details col-md-4">
                  <h5 className="product-title">{event.eventtitle}</h5>

                    <h3 className="product-title">{this.formatDate(event.eventdate)}</h3>
                     <h4>Time  <span >{event.time}</span></h4>
                
                    <h5>Specification</h5>
                    <p className="product-description">{event.description}</p>
                    <h5 className="price">Price: <div className="price" id="price"><i className="fas fa-pound-sign">{event.discountprice==""?(final_price=event.price): (final_price=(parseInt(event.price)-parseInt(event.discountprice))) }</i>
                    
                    { event.discountprice!=""?
                        <span className="price-s" id="discountprice"><i className="fa fa-pound-sign" style={{textDecoration: 'line-through'}}>{event.price}</i></span>:""
                    }
                      </div>
                      </h5>
                    <div className="action">
                  <button onClick={() => { this.addToBook(event._id,final_price, event.eventtitle,event.images[0], event.eventdate) }} className="thm-btn-dynamic-radius book-btn2">Book Now</button> 
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
    </div>
       </div>

        }else{
          eventDescription = <div>Event is loading....!</div>
        }
        
        return (
     <div>
         
         {eventDescription}
     </div>
  
    );
  }
}
const mapStateToProps = state => {
  return {
      auth: state.auth,
  }
}

const mapDispatchToProps = dispatch => {
  return {
     addToBook: (token, cartItem) => dispatch(bookActions.addToBook(token, cartItem)),
     getbookItems: (token, userId) => dispatch(bookActions.getbookItems(token, userId)),
    getToken: () => dispatch(authActions.getToken())
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(Eventdetails);
