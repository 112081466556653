import React, { Component } from 'react';
import './style.css';
import {Link} from 'react-router-dom';
class ThankYou extends Component{

    render() {

        const queryParams = this.props.location.search.split("?")[1];
        const orderId = queryParams.split("=")[1];

        return (
            <div className="cart-wrap">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <div class="alert-message alert-message-success">
                           <h4>
                             Thank you for your order
                           </h4>
                           <div className="Card">
                                <p className="CardText">Order id is: {orderId.toLocaleUpperCase()}</p>
                        </div>
                       </div>  
                  </div>
                </div>
            </div>
            <section className="video-card-one pt-40 pb-40">
                         <div className="container">
                           <div className="col-lg-12 col-xs-12 sp">
                        <p>Check Your  Order Details</p>
                        <Link to="/orders"  target="_blank" className="thm-btn-dynamic-radius  book-btn2" data-target="#myModal" >Check Now
                        </Link>
                          </div>
                         </div>
                     </section>
        </div>
        );
    }

}

export default ThankYou;