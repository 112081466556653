import React, { Component } from 'react'
import { Link, Redirect } from 'react-router-dom';
import MobileTypeInput from '../components/UI/MobileTypeInput';
import * as authActions from '../store/actions/authActions';
import { connect } from 'react-redux';
import Error from '../components/Error';
import swal from 'sweetalert';

export class Login extends Component {
  state = {
    redirectToReferrer: false,
    email: '',
    password: '',
    isError: false,
    errorMessage: ''
}

textHandler = (e) => {
    this.setState({
        [e.target.name]: e.target.value
    })
}

setError = (error, message) => {
    this.setState({
        error: error,
        errorMessage: message
    })
}

loginHandler = (e) => {
    e.preventDefault();

    if(this.state.email === ''){
        this.setError(true, 'Enter Email'); return;
    }

    const emailPattern = new RegExp(/[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/);
    if(!emailPattern.test(this.state.email)){
        this.setError(true, 'Invalid Email Address'); return;
    }

    if(this.state.password === ''){
        this.setError(true, 'Enter Password'); return;
    }

    this.props.authenticate(this.state.email, this.state.password)
    .then(response => {
        console.log(response);
        if(response.hasOwnProperty('token')){
            window.localStorage.setItem('auth', JSON.stringify(response))
            this.setState({
                redirectToReferrer: true
            });
        }
    })
    .catch(error => {
        console.log(error);
    })
}

componentDidMount() {
    if(!this.props.auth.isAuthenticated){
        this.props.getToken()
        .then(result => {
            // result will be either true or false
            if(result){
                this.setState({
                    redirectToReferrer: true
                });
                swal({
                  title: "Done!",
                  text: "Success",
                  icon: "success",
                  timer: 1000,
                  button: false
                })
            }
            
        })
        .catch(er => {
          swal({
            title: "Done!",
            text: "LogIn not done",
            icon: "error",
            timer: 1000,
            button: false
          })
        });
    }
}
render() {

      if(this.state.redirectToReferrer){
        return <Redirect to="/shop" />
    }

        return (
            <div>
         <section className="page-header">
                                            <div className="container">
                                            <div className="col-lg-12 text-center">
                                                <h2>Login...</h2>
                                            </div>
                                            </div>
                  </section>{/* /.page-header */}
<div className=" login-section ptb-100">
  <div className="container">
    <div className="row">
      <div className=" col-lg-6">
        <div className=" login-form left-form">
        
          <div className="login-title">
            <h3>Welcome</h3>
            <p>Please Login to your account.</p>
          </div>                       
          <form onSubmit={this.loginHandler} autoComplete="off">
            <div className="row">
            
              <div className="col-lg-12">
                <div className="form-group">
                <MobileTypeInput type="text" placeholder="Email"value={this.state.email} textHandler={this.textHandler} name="email" />
                </div>
              </div>
              <div className="col-lg-12">
              <div className="form-group">
              <MobileTypeInput  type="password" placeholder="Password" value={this.state.password} textHandler={this.textHandler} name="password"
                  />
               <Error>{this.state.errorMessage}</Error>
           
              </div>
              </div>
              <div className="col-lg-12">
                <div className="send-btn">
                  <button type="submit" className="default-btn-one">Login</button>
                </div>
              </div>
      
             
              <div className="col-lg-7 text-center" style={{marginTop: 15, fontSize: 14}}>
              <Link to="/register" className="default-btn-one" style={{color: 'rgb(80, 81, 80)'}}>Don't Have An Account? Sign Up</Link>
              </div>

            </div>
          </form>
        </div>
      </div>
      <div className="col-lg-6">
      
      <div className="video-card-two__box">
                    <img src="assets/images/blog/img6.jpg" alt />
                    {/* /.video-card-two__box-btn */}
      </div>{/* /.video-card-two__box */}
      </div>
    </div>
  </div>
</div>
</div>
            
        )
    }
}
const mapDispatchToProps = dispatch => {
  return {
      authenticate: (email, password) => dispatch(authActions.authenticate(email, password)),
      getToken: () => dispatch(authActions.getToken())
  }
}
const mapStateToProps = state => {
  return {
      auth: state.auth
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(Login);
