import React, { Component } from 'react'
import {Link} from 'react-router-dom';

export class Services extends Component {
    render() {
        return (
            <div>
                  <section className="page-header">
                    {/* /.page-header__bg */}
                    <div className="container">
                    <div className="col-lg-12 text-center">
                        <h2>Services</h2>
                    </div>
                    </div>{/* /.container */}
                </section>{/* /.page-header */}
                  <section className="service-one smooth pt-110 pb-110">
              <div className="container">
                <div className="block-title">
                  <h3 style={{color: '#00aeef'}}>Services that are available</h3>
                </div>{/* /.block-title */}
                <div className="row">
                  <div className="col-md-6 col-lg-3">
                    <div className="service-one__box">
                      <img src="assets/images/shapes/prayer-icon.png" />
                      <h3><Link to="/prayer">Prayer</Link></h3>
                      <p>Read more on the types of prayers we offer along with information on how to make a booking.</p>
                    </div>{/* /.service-one__box */}
                  </div>{/* /.col-md-6 col-lg-3 */}
                  <div className="col-md-6 col-lg-3">
                    <div className="service-one__box">
                      <img src="assets/images/shapes/langar-icon.png" />
                      <h3><Link to="/langer" target="_blank">Langar</Link></h3>
                      <p>If you're interested in booking a langar at the Gurdwara, please get in touch and we will advise you further.</p>
                    </div>{/* /.service-one__box */}
                  </div>{/* /.col-md-6 col-lg-3 */}
                  <div className="col-md-6 col-lg-3">
                    <div className="service-one__box ">
                      <img src="assets/images/shapes/funeral-icon.png" />
                      <h3><Link to="/funeral" target="_blank">Funeral</Link></h3>
                      <p>We guide you through the process and explain what you need to do following a bereavement.</p>
                    </div>{/* /.service-one__box */}
                  </div>{/* /.col-md-6 col-lg-3 */}
                  <div className="col-md-6 col-lg-3">
                    <div className="service-one__box">
                      <img src="assets/images/shapes/wedding-icon.png" />
                      <h3><Link to="/wedding">Wedding</Link></h3>
                      <p>Make your special day memorable with a wedding at Gurdwara Baba Vadbhag Singh Ji.</p>
                    </div>{/* /.service-one__box */}
                  </div>{/* /.col-md-6 col-lg-3 */}
                </div>{/* /.row */}
              </div>{/* /.container */}
            </section>{/* /.service-one */}
                
            </div>
        )
    }
}

export default Services
