import React, { Component } from 'react';
import * as authActions from '../store/actions/authActions';
import { connect } from 'react-redux';
import { base_url } from '../constants';
import {Redirect} from 'react-router-dom';
import './style.css';
import {Link} from 'react-router-dom';

import swal from 'sweetalert';

class Orders extends Component{

    constructor(props) {
        super(props);
        this.state = {
            redirect: false,
            ordersList: []
        }
    
    }
    componentDidMount(){

        if(!this.props.auth.isAuthenticated){
            this.props.getToken()
            .then(result => {
                if(result){
                    this.getOrders();
                }else{
                    this.props.history.push('/login');
                }
            })
        }else{
            this.getOrders();
        }

        
    }

    getOrders = () => {
        console.log(this.props.auth.isAuthenticated)
        const token =  this.props.auth.token;
        const userId = this.props.auth.user.userId;
        fetch(`${base_url}/order/getorders/${userId}`, {
            headers: {
                'Content-Type': 'application/json',
                'auth-token': token
            }
        })
        .then(response => response.json())
        .then(jsonResponse => {
            console.log(jsonResponse);
            this.setState({
                ordersList: jsonResponse.message
            });
        })
        .catch(error => {
            console.log(error);
        })
    }

    formatDate = (date) => {
        let d = new Date(date);
        return `${d.getDate()}/${d.getMonth() + 1}/${d.getFullYear()}`;
    }

    getOrderTotal = (id) => {
        const singleOrder = this.state.ordersList.find(order => order._id === id);
        let orderTotal = 0;
        singleOrder.order.forEach(order => {
            orderTotal = orderTotal + (order.price * order.quantity)
        });

        return orderTotal;
    }
    logout = () => {
        localStorage.removeItem("auth");
        localStorage.clear();
        this.setState({ redirect: true });
        swal({
            title: "Done!",
            text: "Logout Success",
            icon: "success",
            timer: 1000,
            button: false
          })
    
    }
    

    render (){
        if (this.state.redirect) {
            return (
              
                <Redirect to={'/'} />
            )
        }
        return (
            <React.Fragment>
                <div>
                <section className="page-header">
                    {/* /.page-header__bg */}
                    <div className="container">
                    <div className="col-lg-12 text-center">
                        <h2>My Account</h2>
                    </div>
                    </div>{/* /.container */}
                </section>{/* /.page-header */}
                <section className="page-product" style={{marginTop: 30, marginBottom: 29}} >
                    <div className="container">
                    <div className="row">
                        <aside className="col-md-3">
                        <div className="card">
                            <div className="card-header">
                            <h3 className="card-title">Profile</h3>
                            </div>
                            <div className="card-body text-center item-user border-bottom">
                            <div className="profile-pic">
                                <div className="profile-pic-img">
                                <span className="bg-success dots" data-toggle="tooltip" data-placement="top" title data-original-title="online" />
                                <img src="./assets/images/events/user.jpg" className="brround" alt="user" />
                                </div>
                              </div>
                            </div>
                            <div className="item1-links dashboard mb-0">
                            <Link to= "/orders" className=" d-flex  border-bottom" style={{color:"rgb(87, 80, 78)" }}>
                                <span className="icon1 mr-3"><i className="far fa-shopping-cart" /></span> My Orders
                             </Link>
                            <Link to= "/bookorder"  className=" d-flex border-bottom" style={{color:"rgb(87, 80, 78)" }}>
                                <span className="icon1 mr-3"><i class="fa fa-address-book" ></i></span> My Event
                            </Link>
                            <Link to= "/donationamount"  className=" d-flex border-bottom" style={{color:"rgb(87, 80, 78)" }}>
                                <span className="icon1 mr-3"><i class="fa fa-id-badge"></i></span> My Donation
                            </Link>
                            <a href="/" className="d-flex" style={{color:"rgb(87, 80, 78)" }}>
                                <span className="icon1 mr-3" onClick={this.logout}><i className="fa fa-sign-out" /> Log Out </span>
                            </a>
                            </div>
                        </div>
                        {/* card.// */}
                        </aside>
                        <main className="col-md-9">
                        <div className="main-heading">My Order</div>
                          {
                            this.state.ordersList.map(order => {
                                return (
                                    <div key={order._id} className="Order">
                                        <div className="OrderHeader">
                                            <a href="#">{order._id}</a>
                                        </div>
                                        <div className="OrderDescription">
                                            <div className="od1">
                                                <p className="odtitle">Delivered Address</p>
                                                <p>{`${order.address.address} ${order.address.cityDistrictTown} ${order.address.state} - ${order.address.pinCode}`}</p>
                                            </div>
                                            <div className="od2">
                                                <p className="odtitle">Payment Type</p>
                                                <a className="odp">{order.paymentType}</a>
                                            </div>
                                            <div className="od3">
                                                <p className="odtitle">Payment Status</p>
                                                <a className="odp">{order.paymentStatus}</a>
                                            </div>
                                        
                                        </div>
                                        <div>
                                            {order.order.map(item => (
                                                <div key={item._id} style={{display: 'flex', alignItems: 'center', margin: '5px 0', borderBottom: '1px solid #cecece'}}>
                                                    <div className="col-lg-6">
                                                    <div className="ImageContainer">
                                                        <img  className="productImg1" src={`${base_url}/productImages/`+ item.image}/>
                                                       </div>
                                                    </div>
                                                      
                                                    <div className="col-lg-6">
                                                        <p className="odtitle">{item.name}</p>
                                                        <div style={{fontSize: '14px', color: '#555', fontWeight: 'bold'}}>
                                                        <p>Qty: {item.quantity}</p>
                                                        <p>£{item.price}</p>
                                                        </div>
                                                       
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                        <div className="OrderFooter">
                                            <p>Ordered On <span>{this.formatDate(order.orderDate)}</span></p>
                                            <h6>Total Amount :<span>£{this.getOrderTotal(order._id)}</span></h6>
                                        </div>
                                    </div>
                                  
                                )
                            })
                        }       
                        </main>
                   
                    </div>
                    </div>
                </section>
                </div>
        </React.Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        auth: state.auth
    }
}
const mapDispatchToProps = dispatch => {
    return {
        getToken: () => dispatch(authActions.getToken())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Orders);