import React, { Component } from 'react'

export class Charity extends Component {
    render() {
        return (
            <div>
                <section className="page-header">
                    <div className="container">
                    <div className="col-lg-12 text-center">
                        <h2>Charity</h2>
                    </div>
                    </div>
                </section>  
            </div>
        )
    }
}

export default Charity
