import React, { Component } from 'react';
import Header from '../include/Header';
import * as authActions from '../store/actions/authActions';
import { connect } from 'react-redux';
import AddressForm from './AddressForm';
import CartPrice from '../components/CartPrice';
import DeliveryAddress from './DeliveryAddress';
import RadioButton from '../components/UI/RadioButton';
import * as cartActions from '../store/actions/cartActions';
import { base_url } from '../constants';
import Paypal from '../Pages/Paypal';



class PlaceOrder extends Component{

    state = {
        addresses: [],
        address: {
            fullName: "",
            mobileNumber: "",
            pinCode: "",
            locality: "",
            address: "",
            cityDistrictTown: "",
            state: "",
            landmark: "",
            alternatePhoneNumber: ""
        },
        order: [],
        selectedAddress: '',
        existingAddress: false,
        newAddress: false,
        isAddressConfirm: false,
        isOrderConfirm: false,
        data:'',
        selectedPaymentType: 'paypal',
        paymentTypes: [
            {id: 1, value: 'paypal', label: 'Paypal', isActive: true},
        ],
    }

    componentDidMount() {
        if(!this.props.auth.isAuthenticated){
            this.props.getToken()
            .then(result => {
                if(result){

                    this.getAddresses();

                }else{
                    this.props.history.push('/login');
                }
            })
            .catch(error => {
                console.log(error);
            })
        }else{
            this.getAddresses();
        }
    }

    getAddresses = () => {
        const userId = this.props.auth.user.userId;
        fetch(`${base_url}/user/get-addresses/`+userId, {
            headers: {
                'auth-token': this.props.auth.token
            }
        })
        .then(response => {
            if(response.status == 200){
                return response.json();
            }else{
                throw new Error('Something went wrong');
            }
        })
        .then(jsonResponse => {
            console.log(jsonResponse)
            this.setState({
                addresses: jsonResponse.message.address
            })
        })
        .catch(error => {
            console.log(error);
        });
    }

    inputHandler = (e) => {
        const address = this.state.address;
        const updatedAddress = {
            ...address,
            [e.target.name] :  e.target.value
        };
        this.setState({
            address: updatedAddress
        })
    }

    addressSelector = (e) => {

        this.setState({
            selectedAddress: e.target.value,
            existingAddress: true,
            newAddress: false
        });

    }

    newAddressSelection = (e) => {
        this.setState({
            selectedAddress: 'newAddressId',
            existingAddress: false,
            newAddress: true
        });
    }

    addressSubmitHandler = (e) => {
        e.preventDefault();
        console.log(this.state.address)

        const address = {
            userId: this.props.auth.user.userId,
            address: this.state.address
        }

       fetch(`${base_url}/user/new-address`, {
            headers: {
                'Content-Type': 'application/json',
                'auth-token': this.props.auth.token
            },
            method: 'POST',
            body: JSON.stringify(address)
       })
       .then(response => response.json())
       .then(jsonResponse => {
           console.log('new address');
           console.log(jsonResponse);
           console.log('new address');
          
           const updatedAddressList = jsonResponse.message.address;
           this.setState({
                isAddressConfirm: true,
                address: {
                    ...this.state.address,
                    ...address.address
                },
                addresses: updatedAddressList,
                selectedAddress: updatedAddressList[updatedAddressList.length - 1]._id
            });
           
       })
       .catch(error => {
           console.log(error);
       })
    }

    confirmDeliveryAddress = () => {
        this.setState({
            isAddressConfirm: true
        });

    }

    confirmOrder = () => {
        this.setState({
            isOrderConfirm: true
        })
    }

    selectPaymentOption = (e) => {
        this.setState({
            selectedPaymentType: e.target.value
        })
    }
    transactionSuccess = async () => {

        if(!this.state.isOrderConfirm){
            return;
        }

        if(this.state.selectedPaymentType !== 'paypal'){
            return;
        }

        const order = this.props.cart.cartItem.map(item => {
            return {
                product: item.product,
                price: item.price,
                quantity: item.quantity,
                name :item.name,
                image :item.image,
            }
        });
        const alladdress = this.state.addresses.map(add => {
            return {
                fullName: add.fullName,
                mobileNumber: add.mobileNumber,
                pinCode: add.pinCode,
                address :add.address,
                cityDistrictTown :add.cityDistrictTown,
                landmark :add.landmark,
                locality:add.locality,
                state:add.state,
                alternatePhoneNumber :add.alternatePhoneNumber,
            }
        });
        try{

            const response = await fetch(`${base_url}/order/create`,{
                headers: {
                    'Content-Type': 'application/json',
                    'auth-token': this.props.auth.token
                },
                body: JSON.stringify({
                    user: this.props.auth.user.userId,
                    username:this.props.auth.user.name,
                    address: this.state.selectedAddress,
                    alladdress:alladdress,
                    order: order,
                    paymentType: 'paypal',
                    paymentStatus: 'success',
                }),
                method: 'POST'
            });

            const jsonResponse = await response.json();
            if(response.status === 201){
                this.props.clearCart();
                this.props.history.push({
                    pathname: '/thank-you',
                    search: '?orderid='+jsonResponse.message._id,
                    state: jsonResponse.message
                });
            }

        }catch(error){
            console.log(error);
        }
        
    }
    transactionError = () => {
       console.log('Payment unsuccessfull');
    }
  

    transactionCanceled = () => {
        console.log('Transaction canceled')
    }

    render() {
        let tot_mant = 0;
        let address;

        this.props.cart.cartItem.map(item => (
            tot_mant = tot_mant + parseInt(item.total)
        ))

        if(this.state.isAddressConfirm && !this.state.newAddress){
            address = this.state.addresses.find(address => address._id === this.state.selectedAddress);
        }else{
            address = this.state.address;
        }

        return (
            <React.Fragment>
                <div className="cart-wrap">
                  <div className="container">
                  <div className="row">
                      <div className="col-lg-8">

                            <div className="Card">
                                <p className="CardText">Login {this.props.auth.isAuthenticated ? <i className="fas fa-check"></i> : null}</p>
                                <p className="CardText">Email: {this.props.auth.user.email}</p>
                            </div>

                            {
                                this.state.isAddressConfirm ? 
                                 <div className="Card">
                                     <p className="CardText">Delivery Address {this.state.isAddressConfirm ? <i className="fas fa-check"></i> :'Type Your Address'}</p>
                                     <p>
                                        <span>{`${address.fullName} - ${address.mobileNumber} - `}</span>
                                        <span>{ `${address.address}, ${address.cityDistrictTown}, ${address.state} - ${address.pinCode}`}</span>
                                        </p>
                                 </div> :
                                 <React.Fragment>
                                <div className="Card">
                                    <h4>Delivery Address</h4>
                                    {
                                        this.state.addresses.length && this.state.addresses.map(address => 
                                            <DeliveryAddress 
                                                key={address._id} 
                                                onAddressSelection={this.addressSelector} 
                                                value={this.state.selectedAddress}
                                                address={address} />
                                        )
                                    }
                                    {
                                        this.state.existingAddress ?
                                        <div className="DeliveryButtonContainer" >
                                            <button onClick={this.confirmDeliveryAddress} className="thm-btn-dynamic-radius  book-btn2">Deliver Here</button>
                                        </div> : null
                                    }
                                    
                                </div>
                                <div className="Card">
                                    <div>
                                        <RadioButton 
                                            name="address"
                                            label="Add new Address"
                                            value={this.state.selectedAddress}
                                            onChange={this.newAddressSelection}
                                        />
                                    </div>
                                    {
                                        this.state.newAddress ? 
                                            <AddressForm
                                                address={this.state.address}
                                                inputHandler={this.inputHandler}
                                                addressSubmitHandler={this.addressSubmitHandler}
                                            /> : null
                                    }
                                    
                                    
                                </div>
                                </React.Fragment>
                            }
                            
                               

                                {
                                    this.state.isOrderConfirm ? 
                                    <div className="Card">
                                        <p className="CardText">Order Summary <i className="fas fa-check"></i> </p>
                                    </div> : 
                                    this.state.isAddressConfirm ? 
                                    <div className="Card">
                                        <h4 className="CardText">Order Summary </h4>
                                        {
                                            this.props.cart.cartItem.map(item => (
                                                <div key={item.product} style={{display: 'flex', margin: '5px 0', alignItems: 'center'}}>
                                                    <div className="ImageContainer">
                                                        <img className="productImg1" src={`${base_url}/productImages/`+ item.image} alt="" />
                                                    </div>
                                                    <div className="col-lg-6">
                                                        <h5>{item.name}</h5>
                                                        <h6>Quantity : {item.quantity}</h6>
                                                        <h6>£{item.total}</h6>
                                                    </div>
                                                </div>
                                            ))
                                        }
                                        <button onClick={this.confirmOrder} className="thm-btn-dynamic-radius  book-btn2" >Continue</button>
                                    </div>
                                 : null
                                }
                                
                                {
                                    this.state.isOrderConfirm ? 
                                    <div className="Card">
                                    <h4 className="CardText">Payment Option</h4>

                                       <Paypal  toPay={tot_mant}  onSuccess={this.transactionSuccess} transactionError={this.transactionError}  transactionCanceled={this.transactionCanceled}/> 
                                    

                                </div> : null
                                }

                              </div>
                            <div  class="col-lg-4">
                            <div class="cart-totals">
                                <CartPrice />
                             </div>
                             </div> 
                             </div>

                            
                    </div>
  
                </div>
                
            </React.Fragment>
        );
    }

}

const mapStateToProps = state => {
    return {
        auth: state.auth,
        cart: state.cart
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getToken: () => dispatch(authActions.getToken()),
        clearCart: () => dispatch(cartActions.clearCart())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PlaceOrder);