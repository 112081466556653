import React, { Component } from 'react'

export class Project extends Component {
    render() {
        return (
            <div>
                   <section className="page-header">
                    {/* /.page-header__bg */}
                    <div className="container">
                    <div className="col-lg-12 text-center">
                        <h2>Project</h2>
                    </div>
                    </div>{/* /.container */}
                </section>{/* /.page-header */} 
            </div>
        )
    }
}

export default Project
