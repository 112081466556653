import { base_url } from "../../constants";

export const ADD_TO_BOOK = 'ADD_TO_BOOK';
export const GET_EVENT_DETAILS = 'GET_EVENT_DETAILS';
export const UPDATE_CART = 'UPDATE_CART';
export const CLEAR_CART = 'CLEAR_CART';

export const addToBook = (token, cartItem) => {
    return async dispatch => {
        try{
            const response = await fetch(`${base_url}/book/bookevent`, {
                headers: {
                    'Content-Type': 'application/json',
                    'auth-token': token
                },
                body: JSON.stringify(cartItem),
                method: 'POST'
            });
            const jsonResposne = await response.json();
            if(response.status === 201){
                dispatch({
                    type: ADD_TO_BOOK,
                    cartItem: cartItem
                });
            }

            return jsonResposne;
        }catch(error){
            console.log(error);
        }
    }
}


export const getbookItems = (token, userId) => {
    return async dispatch => {
        try{

            const response = await fetch(`${base_url}/book/bookuser/${userId}`, {
                headers: {
                    'Content-Type': 'application/json',
                    'auth-token': token
                },
                method: 'GET'
            });

            const jsonResposne = await response.json();
            if(response.status === 200){
                dispatch({
                    type: GET_EVENT_DETAILS,
                    cartItems: jsonResposne.message[0]
                });
            }

            return jsonResposne.message[0];

        }catch(error){
            console.log(error);
        }
        
    }
}
export const clearCart = () => {
    return async dispatch => {  
        dispatch({
            type: CLEAR_CART,
            payload: null
        });
    }
}



