import React, { Component } from 'react'
import {Link} from 'react-router-dom';
import { connect } from 'react-redux';
import * as authActions from '../store/actions/authActions';
import swal from 'sweetalert';

export class CartHeader extends Component {

    componentDidMount() {
        this.props.getToken();
    }
    logout = () => {
        localStorage.removeItem("userId");
        localStorage.clear();
        swal({
            title: "Done!",
            text: "Logout Success",
            icon: "success",
            timer: 1000,
            button: false
          })
     window.location="/";
    }
    

    render() {
         let  userAccount = 
         <div className="col-lg-12 text-right"><Link  to="/login" aria-label="instagram"><i class="fa fa-sign-in"></i>Login</Link></div>
         let myaccount="";
             if(this.props.auth.isAuthenticated){               
                userAccount = 
               <Link  to="/" onClick={this.logout} aria-label="instagram"> {( (this.props.auth.user.name).split(' '))[0]} &nbsp;&nbsp;<i class="fa fa-sign-in"></i>Logout</Link>

                   myaccount= <Link to="/account" aria-label="instagram">My Account</Link>               
                 }
        return (
            <div className="container">
                   <div className="row">
                       <div className="col-lg-5 text-left">
                       <div className="main-header__top welcom">
                     <p>Welcome to Gurudwara Baba Vadbhag Singh Ji</p>
                         </div>
                       </div>
                       <div className="col-lg-3 text-center" >  
                        <div class="main-header__social aion">
                        <a href="https://twitter.com/gurdwarabvs" aria-label="twitter" target="_blank"><i class="fab fa-twitter"></i></a>
                        <a href="https://www.facebook.com/Gurdwara-Baba-Vadbhag-Singh-Ji-112921903819470/"  target="_blank" aria-label="facebook"><i class="fab fa-facebook-square"></i></a>
                        <a href="https://www.youtube.com/channel/UCpZJiTTMdjV3o7bqHLPA5cA" aria-label="pinterest"  target="_blank"><i class="fab fa-pinterest-p"></i></a>
                        <a href="https://www.instagram.com/gurdwarabvs/" aria-label="instagram"  target="_blank"><i class="fab fa-instagram"></i></a>
			<a href="https://www.tiktok.com/gurdwarabvs/" aria-label="instagram"  target="_blank"><i class="fa-brands fa-tiktok"></i></a>
                        <a href="/productcart" aria-label="instagram"><i class="fa fa-shopping-cart"></i><span class="minicart-qty">({this.props.cartCount})</span></a>
                   </div> 
                   </div>
                       <div className="col-lg-4 text-right">
                           <div class="main-header__social aion">
                           {myaccount}&nbsp;&nbsp;
                       {userAccount}&nbsp;</div>
                           </div>
                     
       
      </div>
 </div>
        )
    }
}
const mapStateToProps = state => {
    return {
        auth: state.auth,
        cart: state.cart
    }
}
const mapDispatchToProps = dispatch => {
    return {
        getToken: () => dispatch(authActions.getToken())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CartHeader);