import React, { Component } from 'react';
import Header from '../include/Header';
import * as authActions from '../store/actions/authActions';
import { connect } from 'react-redux';
import Paymentform from './Paymentform';
import { base_url } from '../constants';

export class Paydonation extends Component {
    state = {
        addresses: [],
        address: {
            fullName: "",
            mobileNumber: "",
            address: "",
            amount: "",
            whydonate: ""
        },
    }

    inputHandler = (e) => {
        const address = this.state.address;
        const updatedAddress = {
            ...address,
            [e.target.name] :  e.target.value
        };
        this.setState({
            address: updatedAddress
        })
    }
    addressSubmitHandler = (e) => {
        e.preventDefault();
        console.log(this.state.address)

        const address = {
            userId: this.props.auth.user.userId,
            address: this.state.address
        }

       fetch(`${base_url}/test/createdonate`, {
            headers: {
                'Content-Type': 'application/json',
                'auth-token': this.props.auth.token
            },
            method: 'POST',
            body: JSON.stringify(address)
       })
       .then(response => response.json())
       .then(jsonResponse => {
            this.props.history.push({
                pathname: '/pay',
                state: jsonResponse.message.address
            });
                
           
       })
       .catch(error => {
           console.log(error);
       })
    }
    render() {
        return (
            <React.Fragment>
            <Header />
            <div className="cart-wrap">
              <div className="container">
              <div className="row">
                  <div className="col-lg-6">

                        <div className="Card">
                            <p className="CardText">Login {this.props.auth.isAuthenticated ? <i className="fas fa-check"></i> : null}</p>
                            <p className="CardText">Email: {this.props.auth.user.email}</p>
                        </div>

                  
                             <React.Fragment>
                            <div className="Card">

                                        <Paymentform
                                            address={this.state.address}
                                            inputHandler={this.inputHandler}
                                            addressSubmitHandler={this.addressSubmitHandler}
                                        />
                             
                                
                                
                            </div>
                            </React.Fragment>
                   
                           
                          </div>
                          <div className="col-lg-6">
         
                                <div className="video-card-two__box">
                                            <img src="assets/images/blog/img6.jpg" alt />
                                </div>
                                </div>
                 
                         </div>

                        
                </div>

            </div>
            
        </React.Fragment>
    );
}

}
const mapStateToProps = state => {
    return {
        auth: state.auth,
     
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getToken: () => dispatch(authActions.getToken()),
   
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Paydonation);
