import React, { Component } from 'react'

export class News extends Component {
    render() {
        return (
            <div>
  <section className="page-header">
    {/* /.page-header__bg */}
    <div className="container">
      <div className="col-lg-12 text-center">
        <h2>News</h2>
      </div>
    </div>{/* /.container */}
  </section>{/* /.page-header */}
  <section className="news-page pb-120 pt-120">
    <div className="container">
      <div className="news-3-col">
        <div className="blog-card">
          <div className="blog-card__inner">
            <div className="blog-card__image">
              <img src="assets/images/blog/blog-1-1.jpg" alt />
              <div className="blog-card__date">20 May</div>{/* /.blog-card__date */}
            </div>{/* /.blog-card__image */}
            <div className="blog-card__content">
              <div className="blog-card__meta">
                <a href="#"><i className="far fa-user-circle" /> Admin</a>
                <a href="#"><i className="far fa-comments" /> 2 Comments</a>
              </div>{/* /.blog-card__meta */}
              <h3><a href="#">Our donation is hope for poor childrens</a></h3>
              <p>Lorem ipsum is simply free text used by copytyping refreshing.</p>
              <a href="#" className="blog-card__more"><i className="far fa-angle-right" />Read More</a>
              {/* /.blog-card__more */}
            </div>{/* /.blog-card__content */}
          </div>{/* /.blog-card__inner */}
        </div>{/* /.blog-card */}
        <div className="blog-card">
          <div className="blog-card__inner">
            <div className="blog-card__image">
              <img src="assets/images/blog/blog-1-2.jpg" alt />
              <div className="blog-card__date">20 May</div>{/* /.blog-card__date */}
            </div>{/* /.blog-card__image */}
            <div className="blog-card__content">
              <div className="blog-card__meta">
                <a href="#"><i className="far fa-user-circle" /> Admin</a>
                <a href="#"><i className="far fa-comments" /> 2 Comments</a>
              </div>{/* /.blog-card__meta */}
              <h3><a href="#">Education for Poor Children</a></h3>
              <p>Lorem ipsum is simply free text used by copytyping refreshing.</p>
              <a href="#" className="blog-card__more"><i className="far fa-angle-right" />Read More</a>
              {/* /.blog-card__more */}
            </div>{/* /.blog-card__content */}
          </div>{/* /.blog-card__inner */}
        </div>{/* /.blog-card */}
        <div className="blog-card">
          <div className="blog-card__inner">
            <div className="blog-card__image">
              <img src="assets/images/blog/blog-1-3.jpg" alt />
              <div className="blog-card__date">20 May</div>{/* /.blog-card__date */}
            </div>{/* /.blog-card__image */}
            <div className="blog-card__content">
              <div className="blog-card__meta">
                <a href="news-details.html"><i className="far fa-user-circle" /> Admin</a>
                <a href="news-details.html"><i className="far fa-comments" /> 2 Comments</a>
              </div>{/* /.blog-card__meta */}
              <h3><a href="news-details.html">Promoting The Rights of Children</a></h3>
              <p>Lorem ipsum is simply free text used by copytyping refreshing.</p>
              <a href="news-details.html" className="blog-card__more"><i className="far fa-angle-right" />Read More</a>
              {/* /.blog-card__more */}
            </div>{/* /.blog-card__content */}
          </div>{/* /.blog-card__inner */}
        </div>{/* /.blog-card */}
        <div className="blog-card">
          <div className="blog-card__inner">
            <div className="blog-card__image">
              <img src="assets/images/blog/blog-1-1.jpg" alt />
              <div className="blog-card__date">20 May</div>{/* /.blog-card__date */}
            </div>{/* /.blog-card__image */}
            <div className="blog-card__content">
              <div className="blog-card__meta">
                <a href="news-details.html"><i className="far fa-user-circle" /> Admin</a>
                <a href="news-details.html"><i className="far fa-comments" /> 2 Comments</a>
              </div>{/* /.blog-card__meta */}
              <h3><a href="news-details.html">Fundrising for Early Childhood Rise</a></h3>
              <p>Lorem ipsum is simply free text used by copytyping refreshing.</p>
              <a href="news-details.html" className="blog-card__more"><i className="far fa-angle-right" />Read More</a>
              {/* /.blog-card__more */}
            </div>{/* /.blog-card__content */}
          </div>{/* /.blog-card__inner */}
        </div>{/* /.blog-card */}
        <div className="blog-card">
          <div className="blog-card__inner">
            <div className="blog-card__image">
              <img src="assets/images/blog/blog-1-1.jpg" alt />
              <div className="blog-card__date">20 May</div>{/* /.blog-card__date */}
            </div>{/* /.blog-card__image */}
            <div className="blog-card__content">
              <div className="blog-card__meta">
                <a href="news-details.html"><i className="far fa-user-circle" /> Admin</a>
                <a href="news-details.html"><i className="far fa-comments" /> 2 Comments</a>
              </div>{/* /.blog-card__meta */}
              <h3><a href="news-details.html">School Counseling for Children</a></h3>
              <p>Lorem ipsum is simply free text used by copytyping refreshing.</p>
              <a href="news-details.html" className="blog-card__more"><i className="far fa-angle-right" />Read More</a>
              {/* /.blog-card__more */}
            </div>{/* /.blog-card__content */}
          </div>{/* /.blog-card__inner */}
        </div>{/* /.blog-card */}
        <div className="blog-card">
          <div className="blog-card__inner">
            <div className="blog-card__image">
              <img src="assets/images/blog/blog-1-1.jpg" alt />
              <div className="blog-card__date">20 May</div>{/* /.blog-card__date */}
            </div>{/* /.blog-card__image */}
            <div className="blog-card__content">
              <div className="blog-card__meta">
                <a href="news-details.html"><i className="far fa-user-circle" /> Admin</a>
                <a href="news-details.html"><i className="far fa-comments" /> 2 Comments</a>
              </div>{/* /.blog-card__meta */}
              <h3><a href="news-details.html">Growing Up children in Charity Care</a></h3>
              <p>Lorem ipsum is simply free text used by copytyping refreshing.</p>
              <a href="news-details.html" className="blog-card__more"><i className="far fa-angle-right" />Read More</a>
              {/* /.blog-card__more */}
            </div>{/* /.blog-card__content */}
          </div>{/* /.blog-card__inner */}
        </div>{/* /.blog-card */}
      </div>{/* /.news-3-col */}
      <ul className="list-unstyled post-pagination d-flex justify-content-center align-items-center">
        <li><a href="#"><i className="far fa-angle-left" /></a></li>
        <li><a href="#">01</a></li>
        <li><a href="#">02</a></li>
        <li><a href="#">03</a></li>
        <li><a href="#"><i className="far fa-angle-right" /></a></li>
      </ul>{/* /.post-pagination */}
    </div>{/* /.container */}
  </section>{/* /.news-page */}
</div>

                
    
        )
    }
}

export default News
