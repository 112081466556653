import React, { Component } from 'react'
import * as authActions from '../store/actions/authActions';
import { connect } from 'react-redux';
import { base_url } from '../constants';
import { Link } from 'react-router-dom';
import Paypal from '../Pages/Paypal';


export class Paydonation1 extends Component {
    state = {
        addresses: [],
        address: {
            fullName: "",
            mobileNumber: "",
            address: "",
            amount: "",
            whydonate: ""
        },
        selectedPaymentType: 'paypal',
    }
    componentDidMount() {
        if(!this.props.auth.isAuthenticated){
            this.props.getToken()
            .then(result => {
                if(result){

                    this.getAddresses();

                }else{
                    this.props.history.push('/login');
                }
            })
            .catch(error => {
                console.log(error);
            })
        }else{
            this.getAddresses();
        }
    }

    getAddresses = () => {
        const userId = this.props.auth.user.userId;
        fetch(`${base_url}/test/get-donation/`+userId, {
            headers: {
                'auth-token': this.props.auth.token
            }
        })
        .then(response => {
            if(response.status == 200){
                return response.json();
            }else{
                throw new Error('Something went wrong');
            }
        })
        .then(jsonResponse => {
            console.log(jsonResponse.message.address)
            this.setState({
                addresses: jsonResponse.message.address
            
            });
        })
        .catch(error => {
            console.log(error);
        });
    }
    selectPaymentOption = (e) => {
        this.setState({
            selectedPaymentType: e.target.value
        })
    }
    transactionSuccess = async () => {
        if(this.state.selectedPaymentType !== 'paypal'){
            return;
        }
        const donation = this.state.addresses.map((item ,value)=> {
            return {
                donation: item.donation,
                amount: item.amount,
                fullName :item.fullName,
                mobileNumber :item.mobileNumber,
            }
        });
        try{
            const response = await fetch(`${base_url}/test/createdonation`,{
                headers: {
                    'Content-Type': 'application/json',
                    'auth-token': this.props.auth.token
                },
                body: JSON.stringify({
                    user: this.props.auth.user.userId,
                    donation: donation,
                    paymentType: 'paypal',
                    paymentStatus: 'success',
                }),
                method: 'POST'
            });

            const jsonResponse = await response.json();
            if(response.status === 201){
                this.props.history.push({
                    pathname: '/donationthank',
                    search: '?donateid='+jsonResponse.message._id,
                    state: jsonResponse.message
                });
            }

        }catch(error){
            console.log(error);
        }
        
    }
    transactionError = () => {
        console.log('Payment unsuccessfull');
     }
   
 
     transactionCanceled = () => {
         console.log('Transaction canceled')
     }
    render() {
        let sum_total = 0 ;
        var audienses = this.state.addresses.map((value, index) => (
            sum_total = parseInt(sum_total) + parseInt(value.amount),
                <tr>
            
                     <td class="name-product">
                     {value.fullName}<br></br>
                     </td>
                     <td class="name-product">
                     {value.mobileNumber}<br></br>
                     </td>
                     <td>
                       <div className="total">
                       £{value.amount}
                       </div>
                     </td>
     </tr>
              
         
                      ));
     
        return (
            <div>
            <div>
             <section className="page-header">
                 {/* /.page-header__bg */}
                 <div className="container">
                 <div className="col-lg-12 text-center">
                     <h2>Donate Now</h2>
                 </div>
                 </div>
             </section>
             <div className="cart-wrap">
            <div className="container">
            <div className="row">
            <div className="col-lg-8">
            <div className="Card">
                            <p className="CardText">Login {this.props.auth.isAuthenticated ? <i className="fas fa-check"></i> : null}</p>
                            <p className="CardText">Email: {this.props.auth.user.email}</p>
                        </div>
            
            <div className="table-cart">
                  <table className="table-view">
                    <thead>
                      <tr>
                        <th >Name</th>
                        <th >Contact Number</th>
                        <th >Price</th>
                        
                      </tr>
                    </thead>
                    {audienses} 
                    <tr><td colspan="3"style={{textAlign: 'right'}}>£{sum_total }</td></tr> 
                    </table>
                </div>
             </div>
         
             <div  class="col-lg-4">
                <div class="cart-totals">
                          <h3>Your Donatiom Amount..
                              Click Here To Pay..
                          </h3>
                         {/* <button  type="success" onSuccess={this.transactionSuccess}>ABCED</button> */}
                         <Paypal  toPay={parseInt(sum_total)}  onSuccess={this.transactionSuccess} transactionError={this.transactionError}  transactionCanceled={this.transactionCanceled}/>     
                 </div>
                </div> 
          </div>
          </div>
      </div>
                 
             </div>  
         </div>
        )
    }
}
const mapStateToProps = state => {
    return {
        auth: state.auth,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getToken: () => dispatch(authActions.getToken()),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(Paydonation1);
