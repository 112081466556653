import React, { Component } from 'react'
import {Link} from 'react-router-dom';
import { base_url } from '../constants';
import axios from 'axios';
import swal from 'sweetalert';

export class Footer extends Component {
  constructor(props) {
    super(props);
      this.state = {
      redirect: false,
  
     };
 
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChangeEmail = this.handleChangeEmail.bind(this);
    
  }
  handleChangeEmail(e) {
    this.setState({ email: e.target.value });

  }

  handleSubmit(event) {
    event.preventDefault();
      const data = {
             email : this.state.email,

      }
      axios.post(`${base_url}/email/createmail`,data).
      then(()=>{ 
        this.setState({ redirect: true });
        swal({
          title: "Done!",
          text: "Email Success",
          icon: "success",
          timer: 1000,
          button: false,
        
      })
      window.location="/";
     })

  }
    render() {
        return (
    <div>
  <div>
    <section className="site-footer">
      <div className="main-footer">
        <div className="container">
          <div className="col-sm-12">
            <div className="content">
              <h5 className="footer-widget__title" style={{padding: 20, fontSize: 30}}>Newsletter</h5>
              <p>Join our mailing list for the most up-to-date information.</p>
              <form  onSubmit={this.handleSubmit}>
                <div className="input-group">
                  <input type="email" className="form-control"  name="email"   value={this.state.email} onChange={this.handleChangeEmail} placeholder="Enter your email" />
                  <span className="input-group-btn">
                    <button type="submit" className="btn" >Subscribe Now</button>
                  </span>
                </div>
              </form>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-3 col-md-6 col-sm-12">
              <div className="footer-widget footer-widget__about">
                <a href="index.html" aria-label="logo image">
                  <img src="../assets/images/logo-light.png" className="footer-widget__logo" width={101} alt />
                </a>
                <p>Lorem ipsum dolor sit amet consect etur adi  Find Us</p>
                <ul className="list-unstyled footer-widget__contact">
                  <li><a href="#"><i className="azino-icon-telephone" />0247 664 4401 </a></li>
                  <li><a href="#"><i className="azino-icon-email" />info@gurdwarabvs.org</a></li>
                  <li><a href="#"><i className="azino-icon-pin" />6 Bayton Road, Exhall, Coventry, CV7 9EJ</a></li>
                  <li><a href="#"><i className="fa fa-calendar" />MON - THURS: 10:00 - 8:00</a></li>
                  <li><a href="#"><i className="fa fa-clock" />FRI - SUN: 06:00 - 19:00</a></li>
                </ul>
                {/* /.footer-widget__contact */}
              </div>
        
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12">
              <div className="footer-widget footer-widget__link">
                <h3 className="footer-widget__title">Site Links</h3>
                {/* /.footer-widget__title */}
                <ul className="list-unstyled footer-widget__link-list">
                  <li><Link to="/about">About</Link></li>
                  <li><Link to="/event">Event</Link></li>
                  <li><Link to="/news">News</Link></li>
                  <li><Link to="/shop">Shop</Link></li>
                  <li><Link to="/contact">Contact</Link></li>
                </ul>
                {/* /.footer-widget__link-list */}
              </div>
              {/* /.footer-widget footer-widget__link */}
            </div>
            {/* /.col-lg-3 col-md-6 col-sm-12 */}
            {/* /.col-lg-3 col-md-6 col-sm-12 */}
            <div className="col-lg-3 col-md-6 col-sm-12">
              <div className="footer-widget footer-widget__newsletter">
                <h3 className="footer-widget__title">Policies</h3>
                {/* /.footer-widget__title */}
                <ul className="list-unstyled footer-widget__link-list">
                  <li><Link to="/privacypolicy">Privacy Policy</Link></li>
                  <li><a href="/service">Service Policy</a></li>
                  <li><Link to="/returnpolicy">Return Policy</Link></li>
                  <li><Link to="/terms&condtions">Terms &amp; Conditions</Link></li>
                  <li><a href="assets/images/causes/gurudwara.pdf" target="_blank">Standing Order</a></li>
                </ul>
                {/* /.footer-widget__newsletter-form */}
                <div className="mc-form__response" />
                {/* /.mc-form__response */}
              </div>
              {/* /.footer-widget footer-widget__newsletter */}
            </div>
            {/* /.col-lg-3 col-md-6 col-sm-12 */}
            <div className="col-lg-3 col-md-6 col-sm-12">
              <div className="footer-widget footer-widget__blog">
              
                {/* /.footer-widget__title */}
          
              </div>
            </div>
          </div>
          {/* /.row */}
        </div>
      </div>
      {/* /.main-footer */}
      <div className="footer-bottom">
        <div className="container">
          <div className="col-lg-12 col-md-12 col-sm-12 text-center">
            <div className="footer-social">
              <a href="https://twitter.com/gurdwarabvs" aria-label="twitter"><i className="fab fa-twitter" /></a>
              <a href="https://www.facebook.com/Gurdwara-Baba-Vadbhag-Singh-Ji-112921903819470/" aria-label="facebook"><i className="fab fa-facebook-square" /></a>
              <a href="https://www.youtube.com/channel/UCpZJiTTMdjV3o7bqHLPA5cA" aria-label="pinterest"><i className="fab fa-pinterest-p" /></a>
              <a href="https://www.instagram.com/gurdwarabvs/" aria-label="instagram"><i className="fab fa-instagram" /></a>
            </div>
            <p>Copyright © 2020 Gurdwara Baba Vadbhag Singh Ji | Registered Company No: 06553894 | Registered Charity No: 1139319</p>
          </div>
          {/* /.footer-social */}
        </div>
        {/* /.container */}
      </div>
      {/* /.footer-bottom */}
    </section>

  </div>{/* /.page-wrapper */}
  <div className="mobile-nav__wrapper">
    <div className="mobile-nav__overlay mobile-nav__toggler" />
    {/* /.mobile-nav__overlay */}
    <div className="mobile-nav__content">
      <span className="mobile-nav__close mobile-nav__toggler"><i className="far fa-times" /></span>
      <div className="logo-box">
        <a href="/" aria-label="logo image"><img src="../assets/images/logo-light.png" width={101} alt /></a>
      </div>
      {/* /.logo-box */}
      <div className="mobile-nav__container" />
      {/* /.mobile-nav__container */}
      <ul className="mobile-nav__contact list-unstyled">
        <li>
          <i className="azino-icon-email" />
          <a href="mailto:needhelp@azino.com">needhelp@azino.com</a>
        </li>
        <li>
          <i className="azino-icon-telephone" />
          <a href="tel:666-888-0000">666 888 0000</a>
        </li>
      </ul>{/* /.mobile-nav__contact */}
      <div className="mobile-nav__top">
        <div className="mobile-nav__language">
          <img src="../assets/images/resources/flag-1-1.jpg" alt />
          <label className="sr-only" htmlFor="language-select">select language</label>
          <select className="selectpicker" id="language-select">
            <option value="english">English</option>
            <option value="arabic">Arabic</option>
          </select>
        </div>
        <div className="mobile-nav__social">
          <a href="#" aria-label="twitter"><i className="fab fa-twitter" /></a>
          <a href="#" aria-label="facebook"><i className="fab fa-facebook-square" /></a>
          <a href="#" aria-label="pinterest"><i className="fab fa-pinterest-p" /></a>
          <a href="#" aria-label="instagram"><i className="fab fa-instagram" /></a>
        </div>{/* /.mobile-nav__social */}
      </div>{/* /.mobile-nav__top */}
    </div>
    {/* /.mobile-nav__content */}
  </div>
  {/* /.mobile-nav__wrapper */}
  <div className="search-popup">
    <div className="search-popup__overlay search-toggler" />
    {/* /.search-popup__overlay */}
    <div className="search-popup__content">
      <form action="#">
        <label htmlFor="search" className="sr-only">search here</label>{/* /.sr-only */}
        <input type="text" id="search" placeholder="Search Here..." />
        <button type="submit" aria-label="search submit" className="thm-btn">
          <i className="fa fa-search" />
        </button>
      </form>
    </div>
    {/* /.search-popup__content */}
  </div>
</div>

        )
    }
}

export default Footer
