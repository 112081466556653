import React, { Component } from 'react'
import {Link} from 'react-router-dom';
import CartPrice from '../components/CartPrice';

export class Address extends Component {
      render() {
        return (
      
   <div className="login-section ptb-100" style={{marginTop:120}}>
  <div className="container">
    <div className="row">
      <div className=" col-lg-6">
        <div className=" login-form left-form">
          <div className="login-title">
            <h3>Welcome</h3>
            <p>Please Type Your Address Here...</p>
          </div>                       
          <form onSubmit={this.handleSubmit}>
            <div className="row">
            <div className="col-lg-12">
                <div className="form-group">
                  <input type="text"  name="email" className="form-control" placeholder="Name" />
                </div>
              </div>
              <div className="col-lg-12">
              <div className="form-group">
              <input type="text" className="form-control" name="password" placeholder="Mobile Number" />
              </div>
              </div>
              <div className="col-lg-6">
              <div className="form-group">
              <input type="text" className="form-control" name="password" placeholder="Alternate Phone" />
              </div>
              </div>
              <div className="col-lg-6">
              <div className="form-group">
              <input type="text"  className="form-control" name="password" placeholder="Zip code" />
              </div>
              </div>
              <div className="col-lg-6">
              <div className="form-group">
              <input  type="text"  className="form-control" name="password" placeholder="State" />
              </div>
              </div>
              <div className="col-lg-6">
              <div className="form-group">
              <input  type="text"  className="form-control" name="password" placeholder="District" />
              </div>
              </div>
              <div className="col-lg-6">
              <div className="form-group">
              <textarea  type="text"  className="form-control" name="password" placeholder="Type your Locality" />
              </div>
              </div>
              <div className="col-lg-6">
              <div className="form-group">
              <textarea  type="text"  className="form-control" name="password" placeholder="Type your Landmark" />
              </div>
              </div>
              <div className="col-lg-12">
              <div className="form-group">
              <textarea  type="text"  className="form-control" name="password" placeholder="Type your Address" />
              </div>
              </div>
              <div className="col-lg-12">
              <div className="form-group">
               <select className="form-control"> 
                   <option value="">Select a Option</option>
                      <option value="home">Home</option>
                      <option value="work">Work</option>
               </select>
              </div>
              </div>
              <div className="col-lg-12">
              <div className="send-btn">
                  <button type="submit" className="default-btn-one">Address</button>
                </div>
              </div>
              <div className="col-lg-7 text-center" style={{marginTop: 15, fontSize: 14}}>
                <Link to="/register" className="default-btn-one" style={{color: 'rgb(80, 81, 80)'}}></Link>
              </div>
            </div>
          </form>
        </div>
      </div>
      <div className="col-lg-6">
            <CartPrice />
           </div>
      </div>
    </div>
  </div>

        )
    }
}

export default Address
