import React, { Component } from 'react'

export class Funeral extends Component {
    render() {
        return (
            <div>
                <section className="page-header">
                    {/* /.page-header__bg */}
                    <div className="container">
                    <div className="col-lg-12 text-center">
                        <h2>Sikh Funeral</h2>
                    </div>
                    </div>{/* /.container */}
                </section>{/* /.page-header */}
                <section className="video-card-one  pt-60 pb-60" style={{background: '#f2ecec'}}>
                    <div className="container">
                    <div className="col-lg-12 col-xs-12">
                        <h3>Overview</h3>
                        <p>In Sikhism a funeral ceremony is called “Antam Sanskaar,” or celebration of the completion of life. Rather than lamenting the passing of an individual, Sikhism teaches resignation to the will of the creator, emphasising that death is a natural process, and an opportunity for reunion of the soul with its maker.</p><br></br>
                        <p>In the final moments of life, and at the time of passing, the Sikh family encourages their ailing loved one to focus on the divine by reciting “Waheguru,” or suitable passages of scripture from the Guru Granth Sahib.</p><br></br>
                        <p>In Sikhism, after a death occurs, funeral arrangements are made by the family which includes conducting a Sehaj Paath, or a complete reading the Guru Granth Sahib. The Sehaj paath is carried out over a period of ten days following the funeral ceremony after which formal mourning concludes. </p><br></br>
                        <p>Every Sikh funeral service, however simple or complex, consists of reciting the final prayer of the day, Kirtan Sohila, and the offering of Ardas. Both may be performed prior to cremation, the scattering of ashes, or otherwise disposing of remains.</p>
                    </div>{/* /.col-lg-5 */}
                    </div>{/* /.row */}
                </section>{/* /.video-card-two */}    

                <div className=" login-section ptb-100">
  <div className="container">
    <div className="row">
      <div className=" col-lg-6">
        <div className=" login-form left-form">
          <div className="block-title text-center">
            <h3>Funeral Enquiry</h3>
          </div>                       
          <form >
            <div className="row">
               <div className="col-lg-12">
                <div className="form-group">
                  <input type="text"  name="email"  className="form-control" placeholder="Name" />  
                </div>
              </div>
         
              <div className="col-lg-12">
              <div className="form-group">
              <input  type="text"  className="form-control"  placeholder="Number" />
             </div>
              </div>
              <div className="col-lg-12">
              <div className="form-group">
              <input  type="text"  className="form-control"  placeholder="Email" />
             </div>
              </div>
              <div className="col-lg-12">
              <div className="form-group">
              <textarea name="message" className="form-control" placeholder="Message" />
             </div>
              </div>
              <div className="col-lg-12">
                <div className="send-btn">
                  <button type="submit" className="default-btn-one">Submit</button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
      <div className="col-lg-6">
      
      <div className="video-card-two__box">
                    <img src="assets/images/causes/g.jpg" alt />
                    {/* /.video-card-two__box-btn */}
      </div>{/* /.video-card-two__box */}
      </div>
    </div>
  </div>
</div>
            </div>
        )
    }
}

export default Funeral
