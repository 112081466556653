import React, { useEffect, useState} from 'react'
import {Link} from 'react-router-dom';
import axios from 'axios';
import { base_url } from '../constants';

import RadioBox from '../Section/RadioBox.js';
import { category, price } from '../Section/Data1';
import SearchFeature from '../Section/SearchFeature';


function Event () {
    let final_price=0;
    const [Products, setProducts] = useState([])
    const [Skip, setSkip] = useState(0)
    const [Limit, setLimit] = useState(4)
    const [PostSize, setPostSize] = useState()
    const [SearchTerms, setSearchTerms] = useState("")

    const [Filters, setFilters] = useState({
        continents: [],
        price: []
    })

    useEffect(() => {

        const variables = {
            skip: Skip,
            limit: Limit,
        }

        getProducts(variables)

    }, [])

    const getProducts = (variables) => {
        axios.post(`${base_url}/event/fetchevent`, variables)
            .then(response => {
                if (response.data.success) {
                    if (variables.loadMore) {
                        setProducts([...Products, ...response.data.products])
                    } else {
                        setProducts(response.data.products)
                    }
                    setPostSize(response.data.postSize)
                } else {
                    alert('Failed to fectch product datas')
                }
            })
    }
    const onLoadMore = () => {
        let skip = Skip + Limit;

        const variables = {
            skip: skip,
            limit: Limit,
            loadMore: true,
            filters: Filters,
            searchTerm: SearchTerms
        }
        getProducts(variables)
        setSkip(skip)
    }
    const showFilteredResults = (filters) => {

        const variables = {
            skip: 0,
            limit: Limit,
            filters: filters

        }
        getProducts(variables)
        setSkip(0)

    }

    const handlePrice = (value) => {
        const data = price;
        let array = [];

        for (let key in data) {

            if (data[key]._id === parseInt(value, 10)) {
                array = data[key].array;
            }
        }
        console.log('array', array)
        return array
    }

    const handleFilters = (filters, category) => {

        const newFilters = { ...Filters }

        newFilters[category] = filters

        if (category === "price") {
            let priceValues = handlePrice(filters)
            newFilters[category] = priceValues

        }

       // console.log(newFilters)

        showFilteredResults(newFilters)
        setFilters(newFilters)
    }
    const updateSearchTerms = (newSearchTerm) => {

        const variables = {
            skip: 0,
            limit: Limit,
            filters: Filters,
            searchTerm: newSearchTerm
        }

        setSkip(0)
        setSearchTerms(newSearchTerm)

        getProducts(variables)
    }

        
        return ( 
         <div>
                <section className="page-header">
                    {/* /.page-header__bg */}
                    <div className="container">
                    <div className="col-lg-12 text-center">
                        <h2>Events</h2>
                    </div>
                    </div>{/* /.container */}
                </section>{/* /.page-header */}
                <section className="event-page">
              <div className="container-fluid">
              <div className="team-about__top"> 
                    </div>
                     <div className="row">
                         <div className="col-lg-3">
                          <div className="card">
                            <article className="filter-group">
                                <header className="card-header">
                                <a href="#" data-toggle="collapse" data-target="#collapse_1" aria-expanded="true" className>
                                    <i className="icon-control fa fa-chevron-down" />
                                    <h6 className="title">Event</h6>
                                </a>
                                </header>
                                <div className="filter-content collapse show" id="collapse_1">
                                <div className="card-body">
                                 <SearchFeature refreshFunction={updateSearchTerms} />
                                </div> 
                                </div>
                            </article> 
                                <article>
                                <header className="card-header">
                                    <a href="#" data-toggle="collapse" data-target="#collapse_2" aria-expanded="true" className>
                                    <i className="icon-control fa fa-chevron-down" />
                                    <h6 className="title">Price Range</h6>
                                    </a>
                                </header>
                                    <div className="card-body">
                                      
                                    <RadioBox list={price} handleFilters={filters => handleFilters(filters, "price")}  />
                                    </div>
                                </article>

 
                     </div> {/* card.// */}
                 </div>
                         <div className="col-lg-9">
                         <div className="event-grid">
               
                 
     {Products.map((e, index) => {
                    return (
           <div className="card">
           <div className="event-card-inner">
           
              <div className="event-card-image">
              <div className="event-card-image-inner">
                  
              <Link to={`/events/${e._id}`}><img style={{  width: '200px'}} src={`${base_url}/eventImages/`+ e.images} /></Link>
              </div>
              </div>
              <div className="event-card-content">
              <h3> {e.eventtitle}</h3>
              <h3>£{e.discountprice==""?(final_price=e.price): (final_price=(parseInt(e.price)-parseInt(e.discountprice))) }</h3>
              { e.discountprice!=""?
                        <span className="price-s" id="discountprice"><i className="fa fa-pound-sign" style={{textDecoration: 'line-through'}}>{e.price}</i></span>:""
             }
             
              <ul className="event-card-list text-center">
                  <li>
                  <Link to={`/events/${e._id}`} className="thm-btn-dynamic-radius-cart">View Details</Link>
                  </li>
              </ul>
              </div>

          </div>
        </div>
                        
                        );
                    })}
            
                
      </div>
                          <div className="row">
                          {PostSize >= Limit &&
                    <div className="col-lg-12 text-center">
                    <button className="thm-btn-dynamic-radius book-btn2" onClick={onLoadMore} >Load More</button>
                    </div>
                    }
                          </div>

                         </div>
                         
                    </div>
           </div>
                </section>
</div>

        )
    }


export default Event
