import React, { Component } from 'react';
import MobileTypeInput from '../components/UI/MobileTypeInput';
import * as authActions from '../store/actions/authActions';
import { Link, Redirect } from 'react-router-dom';
import  { connect } from 'react-redux';
import Error from '../components/Error';
import moment from "moment";

class Register extends Component {

    state = {
        redirectToreferrer: false,
        signupForm: {
            name: '',
            email: '',
            password: '',
            repassword: '',
            isError: false,
            errorMessage: ''
        }
    }

    textHandler = (e) => {
        const signupForm = this.state.signupForm;
        const updateSignupForm = {
            ...signupForm,
            [e.target.name] : e.target.value
        }
        this.setState({
            signupForm: updateSignupForm
        })
    }

    setError = (error, message) => {
        const { signupForm } = this.state;
        const updatedSignupForm = {
            ...signupForm,
            isError: error,
            errorMessage: message
        }
        this.setState({
            signupForm: updatedSignupForm
        });
    }

    signupHandler = (e) => {
        e.preventDefault();
        const { signupForm } = this.state;
        if(signupForm.name === ''){
            this.setError(true, 'Enter your Name'); return;
        }
        const emailPattern = new RegExp(/[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/);
        if(!emailPattern.test(signupForm.email)){
            this.setError(true, 'Invalid Email Address'); return;
        }

        if(signupForm.email === ''){
            this.setError(true, 'Enter Email');
            return;
        }
        if(signupForm.password === ''){
            this.setError(true, 'Enter Password');
            return;
        }
        if(signupForm.repassword === ''){
            this.setError(true, 'Enter Repassword');
            return;
        }
        if(signupForm.password !== signupForm.repassword){
            this.setError(true, 'Password dosent match');
            return;
        }

        const user = {
            name: signupForm.name,
            email: signupForm.email,
            password: signupForm.password,
            image: `http://gravatar.com/avatar/${moment().unix()}?d=identicon`
        }

        this.props.signup(user)
        .then(jsonResponse => {
            console.log(jsonResponse);
            this.props.history.push({
                pathname: '/login',
               search: '?signup=success',
               state: jsonResponse.message 
            });
        })
        .catch(error => {
            console.log(error);
        })
    }

    componentDidMount() {
        if(!this.props.auth.isAuthenticated){
            this.props.getToken()
            .then(result => {
                // result will be either true or false
                if(result){
                    this.setState({
                        redirectToreferrer: true
                    });
                }
                
            })
            .catch(er => {
                console.log(er);
            });
        }
    }

    render() {

       const { signupForm, redirectToreferrer }  = this.state;

       if(redirectToreferrer){
           return <Redirect to="/" />
       }

        return (
            <div>
            <section className="page-header">
                                               {/* /.page-header__bg */}
                                               <div className="container">
                                               <div className="col-lg-12 text-center">
                                                   <h2>Register Here...</h2>
                                               </div>
                                               </div>{/* /.container */}
                     </section>{/* /.page-header */}
      <div className=" login-section ptb-100">
     <div className="container">
       <div className="row">
         <div className=" col-lg-6">
           <div className=" login-form left-form">
             <div className="login-title">
               <h3>Welcome</h3>
               <p>Please Register to your account.</p>
             </div>                       
             <form onSubmit={this.signupHandler} autoComplete="off" >
               <div className="row">
                 <div className="col-lg-12">
                   <div className="form-group">
                   <MobileTypeInput 
                                    type="text"
                                    placeholder="Enter Your Name"
                                    value={signupForm.name}
                                    textHandler={this.textHandler}
                                    name="name"
                                />
                  
                   </div>
                 </div>
                 <div className="col-lg-12">
                   <div className="form-group">
                   <MobileTypeInput 
                            type="text"
                            placeholder=" Enter Your Email"
                            value={signupForm.email}
                            textHandler={this.textHandler}
                            name="email"
                        />
                    
                   </div>
                 </div>
                 <div className="col-lg-12">
                 <div className="form-group">
                 <MobileTypeInput 
                            type="password"
                            placeholder="Password"
                            value={signupForm.password}
                            textHandler={this.textHandler}
                            name="password"
                        />
                   
                 </div>
                 </div>
                 <div className="col-lg-12">
                 <div className="form-group">
                 <MobileTypeInput 
                            type="password"
                            placeholder="Re-enter Password"
                            value={signupForm.repassword}
                            textHandler={this.textHandler}
                            name="repassword"
                 />
               <Error>
                  {this.state.signupForm.errorMessage}
               </Error>
                 </div>
                 </div>
                 <div className="col-lg-12">
                   <div className="send-btn">
                     <button type="submit" className="default-btn-one">Register Now</button>
                   </div>
                 </div> 
                 <div className="col-lg-7 text-center" style={{marginTop: 15, fontSize: 14}}>
                   <Link to="/login" className="default-btn-one" style={{color: 'rgb(80, 81, 80)'}}>Have An Account? Log In</Link>
                 </div>
   
               </div>
             </form>
           </div>
         </div>
         <div className="col-lg-6">
         
         <div className="video-card-two__box">
                       <img src="assets/images/blog/img6.jpg" alt />
                       {/* /.video-card-two__box-btn */}
         </div>{/* /.video-card-two__box */}
         </div>
       </div>
     </div>
   </div>
   
               </div>
        );
    }
}

const mapDispatchToProps = dispatch => {
    return {
        signup: (user) => dispatch(authActions.signup(user)),
        getToken: () => dispatch(authActions.getToken())
    }
}
const mapStateToProps = state => {
    return {
        auth: state.auth
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Register);