import React, { Component } from 'react'
import swal from 'sweetalert';
import axios from 'axios';
import {Link} from 'react-router-dom';
import { base_url } from '../constants';

export class Contact extends Component {
  constructor() {
    super();
      this.state = {
      hidden: true,
      input: {},
      errors: {}
     };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  
  }
  handleChange(event) {
    let input = this.state.input;
   input[event.target.name] = event.target.value;
     this.setState({
        input

    });

  }
  handleSubmit(event) {
    event.preventDefault();

    if(this.validate()){

        console.log(this.state);
        let input = {};
       input["name"] = "";
       input["email"] = "";
       input["phone"] = "";
       input["message"] = "";

      this.setState({input:input});

      const registered = {
         name : this.state.input.name,
         email : this.state.input.email,
         phone : this.state.input.phone,
         subject : this.state.input.subject,
         message : this.state.input.message,
  
      }
      axios.post(`${base_url}/contact/createcontact`,registered).
      then(()=>{ 
        swal("Thank You For Your Contact!!!!!", {
          icon: "success",
        });
        
    }).catch(() => {
      swal("Not Inserted",{
        icon: "error",

      });
      
    });
      
    }
    
  

  }

  validate(){
   let input = this.state.input;
   let errors = {};
   let isValid = true;

 if (!input["name"]) {
    isValid = false;
   errors["name"] = "Please enter your name."; 
}
if (!input["email"]) {
     isValid = false;
     errors["email"] = "Please enter your email Address.";
  }
 if (typeof input["email"] !== "undefined") {
    var pattern = new RegExp(/^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/);
     if (!pattern.test(input["email"])) {
        isValid = false;
        errors["email"] = "Please enter valid email address.";

      }
    }
    if (!input["phone"]) {
     isValid = false;
     errors["phone"] = "Please enter your phone number.";

    }
    if (!input["message"]) {
      isValid = false;
      errors["message"] = "Write your Message...";
 
     }

 this.setState({

      errors: errors

 });
 return isValid;

}
 render() {
        return (
            <div>
  <section className="page-header">
    <div className="container">
      <div className="col-lg-12 text-center">
        <h2>Contact</h2>
      </div>
    </div>
  </section>
  <section className="contact-page pt-120 pb-80">
    <div className="container">
      <div className="row">
        <div className="col-lg-5 col-md-5">
          <div className="contact-page__content mb-40">
            <div className="block-title">
              <p><img src="assets/images/shapes/heart-2-1.png" width={15} alt />Contact Us</p>
              <h3>Feel free to write us <br /> a message.</h3>
            </div>
            {/* /.block-title */}
            <p className="block-text mb-30 pr-10">If you have any comments, suggestions or feedback, please contact on below given details. </p>
            <address className="addr">
              <p>Telephone: <a href="callto:#" style={{color:"#f15c22" }}>+07896783205</a></p>
              <p>Fax: <a href="callto:#" style={{color:"#f15c22"}}>+12343456789</a></p>
              <p>E-mail: <a href="mailto:#" style={{color:"#f15c22"}}>vinod.malvi@yahoo.co.uk</a></p>
            </address>
            <div className="footer-social">
              <a href="https://twitter.com/gurdwarabvs" aria-label="twitter"><i className="fab fa-twitter" /></a>
              <a href="https://www.facebook.com/Gurdwara-Baba-Vadbhag-Singh-Ji-112921903819470/" aria-label="facebook"><i className="fab fa-facebook-square" /></a>
              <a href="https://www.youtube.com/channel/UCpZJiTTMdjV3o7bqHLPA5cA" aria-label="pinterest"><i className="fab fa-pinterest-p" /></a>
              <a href="https://www.instagram.com/gurdwarabvs/" aria-label="instagram"><i className="fab fa-instagram" /></a>
            </div>
          </div>
        </div>
        <div className="col-lg-7 col-md-7">
          <h2 style={{padding: 20}}>Contact Form</h2>
          <form  onSubmit={this.handleSubmit} autocomplete="off" className="contact-form-validated contact-page__form form-one mb-40">
            <div className="form-group">
              <div className="form-control">
                <label htmlFor="name" className="sr-only">Name</label>
                <input type="text" name="name" id="name" placeholder="Your Name"  onChange={this.handleChange}/>
                <div className="text-danger" id ="name_error">{this.state.errors.name}</div>
              </div>
             
              <div className="form-control">
                <label htmlFor="email" className="sr-only">email</label>
                <input type="text" name="email" id="email" placeholder="Email Address" onChange={this.handleChange} />
                <div className="text-danger" id ="name_error">{this.state.errors.email}</div>
              </div>
            
              <div className="form-control">
                <label htmlFor="phone" className="sr-only">phone</label>
                <input type="text" name="phone" id="phone" placeholder="Phone Number"  onChange={this.handleChange}/>
                <div className="text-danger" id ="name_error">{this.state.errors.phone}</div>
              </div>
        
              <div className="form-control">
                <label htmlFor="subject" className="sr-only">subject</label>
                <input type="text" name="subject" id="subject" placeholder="Subject" onChange={this.handleChange} />
              </div>
           
              <div className="form-control form-control-full">
                <label htmlFor="message" className="sr-only">message</label>
                <textarea name="message" placeholder="Write a Message"  onChange={this.handleChange}/>
                <div className="text-danger" id ="name_error">{this.state.errors.message}</div>   
              </div>
        
              <div className="form-control form-control-full">
                <button type="submit" className="thm-btn">Submit Message</button>
         
              </div>
           
            </div>
         
          </form>
          <div className="result" />
          {/* /.result */}
        </div>
        {/* /.col-lg-7 */}
      </div>
      {/* /.row */}
    </div>
    {/* /.container */}
  </section>
  <section class="section google-map__home" id="section">
            <iframe  title="template google map" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2430.842257139089!2d-1.4841182841550795!3d52.46388417980364!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x48774c20d0ac8c1d%3A0x407373e2fa668a95!2sGurdwara%20Dhan%20Dhan%20Baba%20Vadbhag%20Singh%20Ji%20Bedworth!5e0!3m2!1sen!2sin!4v1603795567235!5m2!1sen!2sin" class="map__home" ></iframe>
</section>
</div>

        )
    }
}

export default Contact
