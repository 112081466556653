import React, { Component } from 'react'

export class Privacypolicy extends Component {
    render() {
        return (
            <div>
                <section className="page-header">
                    {/* /.page-header__bg */}
                    <div className="container">
                    <div className="col-lg-12 text-center">
                        <h2>Privacy Policy</h2>
                    </div>
                    </div>{/* /.container */}
                </section>{/* /.page-header */}
                <section className="video-card-one pt-60 pb-60" style={{background: '#f2ecec'}}>
                    <div className="container">
                    <div className="col-lg-12 col-xs-12">
                        <p>Our website address is: www.gurdwarabvs.org</p><br />
                        <p> Gurdwara Baba Vadbhag Singh Ji is committed to protecting your privacy. We value our visitors and endeavour to create an enjoyable and safe shopping experience when visiting our website.</p><br />
                        <p>This privacy policy sets out how we use and protect any information that you give when you use this website. We are committed to ensuring that your privacy is protected. Should we ask you to provide certain information by which you can be identified when using this website, then you can be assured that it will only be used in accordance with this privacy statement. We may change this policy from time to time by updating this page.</p><br />
                        <ul className="cookie-imp">
                        <li>Information collected by our website</li>
                        <li>Controlling your personal information</li>
                        <li>Website Security</li>
                        <li>An Introduction to cookies</li>
                        <li>Your consent for cookie deployment by our website.</li>
                        <li>Cookies used by our website</li>
                        <li>Actual cookies deployed</li>
                        <li>How to disable cookies</li>
                        </ul>
                    </div>{/* /.col-lg-5 */}
                    </div>{/* /.row */}
                </section>{/* /.video-card-two */}
                <section className="video-card-one pt-60 pb-60">
                    <div className="container">
                    <div className="col-lg-12 col-xs-12">
                        <h3>Information collected by our website</h3>
                        <p>For the purpose of making an enquiring or placing an order, our website may collect the following information. Your name and appropriate contact information including email address, address, preferences and interests. Other information relevant to customer surveys and/or offers may also be requested. </p>
                        <h3 style={{color: '#f15c22'}}>What we do with the information collected</h3>
                        <p>We require this information to understand your needs and provide you with more information for the product(s)/service(s) you have requested. Your information may also be stored in a CRM system for Internal record keeping. We may use the information to improve our products and services. We will not use the information collected within marketing campaigns, unless the you have agreed to be contacted by this medium. We may contact you by email, phone, fax or mail. We may use the information to customise the website according to your interests.</p>
                    </div>{/* /.col-lg-5 */}
                    </div>{/* /.row */}
                </section>{/* /.video-card-two */}
                <section className="video-card-one pt-60 pb-60" style={{background: '#f2ecec'}}>
                    <div className="container">
                    <div className="col-lg-12 col-xs-12">
                        <h3>Comment forms</h3>
                        <p>When visitors leave comments on the site we collect the data shown in the comments form, and also the visitor’s IP address and browser user agent string to help spam detection.</p><br />
                        <p>An anonymised string created from your email address (also called a hash) may be provided to the Gravatar service to see if you are using it. The Gravatar service privacy policy is available here: https://automattic.com/privacy/. After approval of your comment, your profile picture is visible to the public in the context of your comment.</p>
                        <h3 style={{color: '#f15c22'}}>Media files</h3>
                        <p>If you upload images to the website, you should avoid uploading images with embedded location data (EXIF GPS) included. Visitors to the website can download and extract any location data from images on the website. </p>
                    </div>{/* /.col-lg-5 */}
                    </div>{/* /.row */}
                </section>{/* /.video-card-two */}
                <section className="video-card-one pt-60 pb-60">
                    <div className="container">
                    <div className="col-lg-12 col-xs-12">
                        <h3>Controlling your personal information</h3>
                        <p>You may choose to restrict the collection or use of your personal information in the following ways:</p><br />
                        <ul className="cookie-imp">
                        <li>Whenever you are asked to fill in a form on the website, ensure any checkbox asking for approval to undertake in marketing are left unchecked.</li>
                        <li>If you have previously agreed to us using your personal information for direct marketing purposes, you may change your mind at any time by requesting data removal via the contact information found on this page.</li>
                        <li>If you have previously agreed to us using your personal information for direct marketing purposes &amp; you would like to see what information we hold we can provide this information at any time, simply make a request via the contact information found on this page.</li></ul>
                        <p>We will not sell, distribute or lease your personal information to third parties unless we have your permission or are required by law to do so. We may use your personal information to send you promotional information about third parties which we think you may find interesting if you tell us that you wish this to happen. You may request details of personal information which we hold about you under the Data Protection Act 1998.</p><br />
                        <p>If you believe that any information we are holding on you is incorrect or incomplete, please write to or email us as soon as possible. We will promptly correct any information found to be incorrect.</p>
                        <h3 style={{color: '#f15c22'}}>How long we retain your data</h3>
                        <p>If you leave a comment, the comment and its metadata are retained indefinitely. This is so we can recognise and approve any follow-up comments automatically instead of holding them in a moderation queue.</p><br />
                        <p>For users that register on our website (if any), we also store the personal information they provide in their user profile. All users can see, edit, or delete their personal information at any time (except they cannot change their username). Website administrators can also see and edit that information.</p>
                    </div>{/* /.col-lg-5 */}
                    </div>{/* /.row */}
                </section>{/* /.video-card-two */}
                <section className="video-card-one pt-60 pb-60" style={{background: '#f2ecec'}}>
                    <div className="container">
                    <div className="col-lg-12 col-xs-12">
                        <h3>Web security</h3>
                        <p>We are committed to ensuring that your information is secure. In order to prevent unauthorised access or disclosure, we have put in place suitable physical, electronic and managerial procedures to safeguard and secure the information we collect online..</p><br />
                        <h3>Data breach</h3>
                        <p>In the event of a data breach, we will notify customers within 72 hours of first becoming aware of the breach. </p>
                        <h3 style={{color: '#f15c22'}}>Payment security</h3>
                        <p>We will take reasonable technical and organisational precautions to prevent the loss, misuse or alteration of your personal information. We will never store your credit/debit card information on our website. All electronic transactions you make to or receive from us will be encrypted using SSL technology via and will be transferred and processed by PayPal’s Payment Gateway. Of course, data transmission over the internet is inherently insecure, and we cannot guarantee the security of data sent over the internet. You are responsible for keeping your password and user details confidential. We will not ask you for your password. <b>We do not store credit card details nor do we share customer details with any 3rd parties.</b> </p>
                        <h3>Media files</h3>
                        <p>If you upload images to the website, you should avoid uploading images with embedded location data (EXIF GPS) included. Visitors to the website can download and extract any location data from images on the website. </p>
                        <h3>External links</h3>
                        <p>
                        For privacy specific concerns please contact us via the below details:-<br />
                        Subject: Privacy Concerns<br />
                        Contact Email: info@gurdwarabvs.org
                        </p>
                    </div>{/* /.col-lg-5 */}
                    </div>{/* /.row */}
                </section>{/* /.video-card-two */}
                <section className="video-card-one pt-60 pb-60">
                    <div className="container">
                    <div className="col-lg-12 col-xs-12">
                        <h3>An introduction to cookies</h3>
                        <p>A cookie is a small data file that is automatically placed onto your computer’s hard drive. Cookie files are created by our website and placed onto your device in order to analyse and influence your preferences or behavior. Analytical cookies are designed to track your journey and experience on our website – so that we may tailor your experience and gather data. Overall, cookies help us provide you with a better website, by enabling us to monitor which pages you find useful and which you do not. A cookie in no way gives us access to your computer or any information about you, other than the data you choose to share with us.</p><br />
                        <p>
                        The majority of modern web browsers will automatically allow cookies to be placed onto your device. However you can usually modify your browser’s settings in order to prevent any cookies from being placed onto your device. Setting your browser to block cookies may prevent you from taking full advantage of our website.
                        </p>
                        <h3 style={{color: '#f15c22'}}>Cookies deployed by our website:</h3>
                        <ul className="cookie-imp"><li>ensure that functionality operates as intended</li>
                        <li>remember your choices and preferences during and between visits</li>
                        <li>allow you to share pages via popular social media networks including Facebook and Twitter</li>
                        <li>allow you to interact with our website by leaving comments or opinions</li>
                        <li>post enquiries via online forms</li>
                        <li>track your visit for statistical analysis, allowing us to improve the usability, speed and security of our website</li>
                        </ul>
                        <h3 style={{color: '#f15c22'}}>Cookies are not deployed to:</h3>
                        <ul className="cookie-imp">
                        <li>collect any personal identifiable information (without your express permission)</li>
                        <li>collect any sensitive information (without your express permission)</li>
                        <li>pass data to advertising networks</li>
                        <li>pass personally identifiable data to third parties</li>
                        <li>pay sales commissions</li>
                        </ul>
                        <h3>
                        Your consent for cookie deployment by our website
                        </h3>
                        <p>
                        Upon your first visit to our website, you may have noticed our banner alerting you to the use of cookies by our website. In compliance with the 2011 EU Cookie Law, our website operates on an ‘Implied Consent’ basis. This means that we have assumed that you have ‘opted-in’ for our website to deploy cookies until you choose to deactivate them within your browser settings or via the instructions below. Below you can learn more about the specific cookies deployed by our website, and how they can be disabled. For more information on the EU Cookie Law in the UK, we recommend visiting the Information Commissioner’s Office (ICO) website where you can find the latest information, guidelines and advice on the EU Cookie Law.
                        </p>
                        <h3 style={{color: '#f15c22'}}>
                        Cookies used by our website
                        </h3>
                        <h3>
                        Social network sharing
                        </h3>
                        <p>
                        We encourage users to share our content and/or like our on profile on the popular Social Media websites Facebook, Twitter, YouTube, Google+ and LinkedIn. In order to make ‘Social Sharing’ accessible, our website utilises widgets either provided directly from the Social Networks and/or via amalgamative widgets from third parties such as AddThis. Cookies and privacy implications from the social networks vary and will be dependent on your nominated privacy settings with each Social Network. Social Sharing buttons will only deploy cookies if you are signed to that respective Social Network at the time of being on our website.
                        </p>
                        <h3>
                        Analytical tracking
                        </h3>
                        <p>
                        Our website has Google Analytics installed which allows us to track and compile anonymous visitor statistics. The information collected ranges from simple traffic volume to the type of browser you are viewing our website with. This information is valuable to us not just for marketing analysis and quantification, but to improve the usability, security and load speed of our website content. Google Analytics is a popular, secure, flagship webmaster product from Google. The privacy and security of Google Analytics data is a high priority at Google that you can read more about on Google’s Analytics Data Safeguarding page. Google also offer a Google Analytics Opt-out Browser Add-on that will allow you to automatically Opt-out of all websites that track your activity via Google Analytics. No personal information is collected by Google Analytics.
                        </p>
                    </div>{/* /.col-lg-5 */}
                    </div>{/* /.row */}
                </section>{/* /.video-card-two */}
                <section className="video-card-one pt-60 pb-60" style={{background: '#f2ecec'}}>
                    <div className="container">
                    <div className="col-lg-12 col-xs-12">
                        <h3>Cookie consent check</h3>
                        <p>
                        Upon your first visit to our website, you will notice a subtle banner on the page alerting you to the fact that cookies are being placed onto your computer by our website. In order for our website to remember your choice of opting-in, we store a cookie which expires after the set amount of days below (see table).
                        </p>
                        <h3 style={{color: '#f15c22'}}>
                        WordPress
                        </h3>
                        <p>
                        Our website, either in full or in part, is built on the popular open-source CMS framework – WordPress. WordPress utilises cookies to allow visitors to register, login and comment on our website’s content. If you do not wish to participate in commenting on our website, WordPress will not deploy any cookies onto your device. Cookies will only be created by WordPress if you actively register or comment via the clear forms on our website.
                        </p>
                        <h3>WooCommerce</h3>
                        <p>
                        WooCommerce is a popular eCommerce extension for WordPress, which we use for our website. WooCommerce uses three cookies in order to save your basket contents and session details. No personal information is stored in any of the three WooCommerce cookies. Cookies deployed by WooCommerce are essential for the process of using the cart and checkout functionality.
                        </p>
                        <h3 style={{color: '#f15c22'}}>
                        Enquiry forms
                        </h3>
                        <p>
                        Our website’s visitors can choose to contact us via form(s) on our website. In order to prevent spam enquiries, we protect our forms with anti-spam challenges which ensure the visitor is an actual living person, rather than a computer bot or spider.
                        </p>
                    </div>{/* /.col-lg-5 */}
                    </div>{/* /.row */}
                </section>{/* /.video-card-two */}
                <section className="video-card-one pt-60 pb-60">
                    <div className="container">
                    <div className="col-lg-12 col-xs-12">
                        <h3>Actual cookies deployed</h3>
                        <p>
                        Below is a table of information which lists all cookies deployed and used on our website.
                        </p><br />
                        <div className="table-responsive">
                        <table id="cookies-table">
                            <thead><tr>
                                <th>Cookie Category</th>
                                <th>Cookie Name</th>
                                <th>Cookie Description</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td rowSpan={1}>Cookie Consent</td>
                                <td>complianceCookie</td>
                                <td>This cookie is set by our website once you have seen and acknowledged our Cookie banner. This cookie will expire and automatically delete itself after<strong> [14] </strong>days.</td>
                            </tr>
                            <tr>
                                <td rowSpan={1}>Google Analytics (Universal)</td>
                                <td>_ga</td>
                                <td>The single default cookie for Google Universal Analytics. This sole cookie used by Google Analytics stores a unique client identifier (Client ID) which is set randomly. This cookie is set to expire after 24 months (2 years) and is refreshed each time you visit our website.</td></tr><tr><td rowSpan={5}>Google Analytics (Classic)</td><td>_utma</td><td>A Google Analytics cookie, which keeps track of the number of times, a visitor has been to the site, when their first visit was, and when their last visit occurred.</td></tr><tr><td>_utmb</td><td>A Google Analytics cookie, which creates a timestamp of the exact moment when a visitor enters a site.</td>
                            </tr>
                            <tr>
                                <td>_utmc</td>
                                <td>A Google Analytics cookie, which creates a timestamp of the exact moment when a visitor leaves the site.</td>
                            </tr>
                            <tr>
                                <td>_utmv</td>
                                <td>Used for reporting in Google Analytics classifying the visitor.</td>
                            </tr>
                            <tr>
                                <td>_utmz</td>
                                <td>A Google Analytics cookie which tracks where the visitor came from, what search engine was used, what link was clicked on, what keywords used, and where in the world the site was accessed from.</td>
                            </tr>
                            <tr>
                                <td>AddThis</td>
                                <td>loc, uid/uit, psc, di/dt, atuvc/bt/ssc/ssh/sshs/xtc</td>
                                <td>Allows users to share content via Social Networking websites and email</td>
                            </tr>
                            <tr>
                                <td rowSpan={4}>WordPress</td>
                                <td>wp-settings-{'{'}time{'}'}-[UID]</td>
                                <td>Several WP Settings cookies may be set if you log into the website. The number on the end is your individual user ID from the users database table. This is used to customise your view of admin interface, and possibly also the main site interface.</td>
                            </tr>
                            <tr>
                                <td>wordpress_test_cookie<br />wordpress_<br />wordpress_logged_in</td>
                                <td>These WordPress session cookies are created if and when you log into WordPress either as an administrator or contributor to the website.</td>
                            </tr>
                            <tr>
                                <td>comment_author<br />comment_author_email<br />comment_author_url</td>
                                <td>If you choose to submit a discussion/comment to our website, you will be asked to provide certain information about yourself including your name, email and website address.</td>
                            </tr>
                            <tr>
                                <td>devicePixelRatio</td>
                                <td>This cookie records your device’s pixel ratio. If your screen resolution is a retina or Hi DPI screen, then the website may choose to serve you higher resolution graphics.</td>
                            </tr>
                            <tr>
                                <td>Woocommerce</td>
                                <td>woocommerce_cart_hash</td>
                                <td>These two cookies store information about the cart/basket as a whole and help track data changes in WooCommerce. No personal information is stored within this cookie.</td>
                            </tr>
                            <tr>
                                <td>&nbsp;</td>
                                <td>woocommerce_items_in_cart</td>
                                <td rowSpan={2}>This unique user session cookie pairs the user with their cart/basket contents stored on the database. No personal information is stored within this cookie.</td>
                            </tr>
                            <tr>
                                <td>&nbsp;</td>
                                <td>woocommerce_items_in_cart</td>
                            </tr>
                            </tbody>
                        </table>
                        </div>
                    </div>{/* /.col-lg-5 */}
                    </div>{/* /.row */}
                </section>{/* /.video-card-two */}
                <section className="video-card-one pt-60 pb-60" style={{background: '#f2ecec'}}>
                    <div className="container">
                    <div className="col-lg-12 col-xs-12">
                        <h3>How to disable cookies</h3>
                        <p>Most modern browsers allow you to control your cookie settings for all websites that you browse. You can disable cookie deployment completely by editing your browser settings, however in doing this you may be limiting the functionality that is displayed on our website. To learn how to disable cookies on your preferred browser we recommend reading this advice posted by Google.</p><br />
                        <p>If you are concerned about cookies tracking your movements on the Internet then you may be concerned about spyware. Spyware is the name given to a particular band of cookies that track personal information about you. There are many antispyware programs that you can use to prevent this from happening. Learn more about antispyware software – http://en.wikipedia.org/wiki/Spyware. You call also disable Google Analytics on all websites by downloading the Google Analytics Opt-out Browser Add-on. </p>
                    </div>{/* /.col-lg-5 */}
                    </div>{/* /.row */}
                </section>{/* /.video-card-two */}
                </div>

        )
    }
}

export default Privacypolicy
