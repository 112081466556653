import React, { Component } from 'react'

export class Prayer extends Component {
    render() {
        return (
            <div>
                 <section className="page-header">
                    {/* /.page-header__bg */}
                    <div className="container">
                    <div className="col-lg-12 text-center">
                        <h2>Sikh Prayers</h2>
                    </div>
                    </div>{/* /.container */}
                </section>{/* /.page-header */}
                <section className="section video-card-one  pt-60 pb-90" style={{background: '#f2ecec'}}>
                    <div className="container">
                    <div className="col-lg-12 col-xs-12">
                        <h3>Akhand Paath Sahib</h3>
                        <p>Akhand Paath is the common practise of continuous recitation (without any break) of sacred religious texts in Sikhism. The continuous nonstop recitation of all the verses in the Guru Granth Sahib from the beginning to the end, in 31 Ragas as specified, in all 1430 pages, lasting more than 48 hours by a team of readers.</p><br></br>
                        <p>This ritual is considered very holy and is said to bring peace and solace to the participants and the passive listeners of the recital. During the reading it is tradition for langar (or communal food) to be available at all times, thus requiring the continual service and dedication of those in whose honour the Akhand Paath is being held.</p><br></br>
                        <p>The recital (Paath) is undertaken for various reasons. It can be in honour of a particular occasion; to mark a happy or sad occasion within the family; or simply to increase one’s feeling of connection to Waheguru.</p><br></br>
                        <p>Some of the following scenarios may call for an Akhand Paath depending on the family’s circumstances: a birth, a birthday, recovery from a medical operation, a wedding, a death, a graduation, on achieving a goal like a high school certificate, on passing the driving test, an anniversary, a historic occasion, chasing away evil spirits and curses etc.</p>
                    </div>{/* /.col-lg-5 */}
                    </div>{/* /.row */}
                </section>{/* /.video-card-two */}
                <section className="section video-card-one  pt-60 pb-90">
                    <div className="container">
                    <div className="col-lg-12 col-xs-12">
                        <h3 style={{color: '#f15c22'}}>Seheja Paath</h3>
                        <p>Sahaj Paath is the complete reading of the Sri Guru Granth Sahib from start to finish accomplished at the reader’s pace. A Sahaj Paath may be fulfilled by one or more readers. The pace of the paath is undetermined and will depend entirely on the reader(s). </p><br></br>
                        <p>The reader(s) begin one sahaj paath and in some cases take up to one year to read the whole of the holy Guru Granth Sahib. After they have carried out the bhog of the paath, they begin another paath. So in this way, they are continuously reading Gurbani for a little while perhaps on a daily basis. The sahaj paath can be held at the Gurdwara or at your home.</p>
                    </div>{/* /.col-lg-5 */}
                    </div>{/* /.row */}
                </section>{/* /.video-card-two */}
                <section className="section video-card-one  pt-60 pb-90" style={{background: '#f2ecec'}}>
                    <div className="container">
                    <div className="col-lg-12 col-xs-12">
                        <h3>Sukhmani Sahib</h3>
                        <p>Sukhmani Sahib is the name given to the set of hymns divided into 24 sections which appear in the Sri Guru Granth Sahib, the Sikh Holy Scriptures on page 262. Each section, which is called an Ashtpadi (asht means 8), consists of 8 hymns per Ashtpadi. The word Sukhmani literally means Peace in your mind. </p><br></br>
                        <p>The full recital takes about 90 minutes and is normally undertaken by everyone in the congregation. According to Sikh doctorine, this Bani is believed to bring peace to one’s mind and compoundly peace to the world. This set of 192 hymns were compiled by the fifth Sikh Guru, Guru Arjan Dev Ji</p>
                    </div>{/* /.col-lg-5 */}
                    </div>{/* /.row */}
                </section>{/* /.video-card-two */}
                <div className=" login-section ptb-100">
  <div className="container">
    <div className="row">
      <div className=" col-lg-6">
        <div className=" login-form left-form">
          <div className="block-title text-center">
            <h3>Prayer Enquiry</h3>
          </div>                       
          <form >
            <div className="row">
               <div className="col-lg-12">
                <div className="form-group">
                  <input type="text"  name="email"  className="form-control" placeholder="Name" />  
                </div>
              </div>
         
              <div className="col-lg-12">
              <div className="form-group">
              <input  type="text"  className="form-control"  placeholder="Number" />
             </div>
              </div>
              <div className="col-lg-12">
              <div className="form-group">
              <input  type="text"  className="form-control"  placeholder="Email" />
             </div>
              </div>
              <div className="col-lg-12">
              <div className="form-group">
              <textarea name="message" className="form-control" placeholder="Message" />
             </div>
              </div>
              <div className="col-lg-12">
                <div className="send-btn">
                  <button type="submit" className="default-btn-one">Submit</button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
      <div className="col-lg-6">
      
      <div className="video-card-two__box">
                    <img src="assets/images/causes/g.jpg" alt />
                    {/* /.video-card-two__box-btn */}
      </div>{/* /.video-card-two__box */}
      </div>
    </div>
  </div>
</div>

                </div>
                
       
        )
    }
}

export default Prayer
