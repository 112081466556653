import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as cartActions from '../store/actions/cartActions';
import * as authActions from '../store/actions/authActions';
import { base_url } from '../constants';
import {  Redirect } from 'react-router-dom';
import swal from 'sweetalert';

class Productdetails extends Component{

    state = {
        product:null,
        redirectToReferrer: false
    }

    componentDidMount() {
      if(!this.props.auth.isAuthenticated){
        this.props.getToken()
        .then(result => {
            if(result){
                this.props.getCartItems(this.props.auth.token, this.props.auth.user.userId)
                .then(response => {
                    console.log(response);
                })
                .catch(error => {
                    console.log(error);
                })
            }
        })
        .catch(error => {
            console.log(error);
        })
    }
        const { productId } = this.props.match.params;
        fetch(`${base_url}/product/${productId}`)
        .then(response => response.json())
        .then(jsonResponse => {

            if(jsonResponse.hasOwnProperty('message')){
                console.log(jsonResponse);
                this.setState({
                    product: jsonResponse.message
                })
            }

        })
        .catch(error => {
            console.log(error);
        });



    }
    addToCart = (productId, final_price, producttitle,images) => {

      if(!this.props.auth.isAuthenticated){
          this.props.history.push('/login');
          return;
      }

      const { auth } = this.props;
      const cartItem = {
          user: auth.user.userId,
          username:auth.user.name,
          product: productId,
          name: producttitle,
          quantity: 1,
          price: final_price,
          image: images
      }
      console.log(cartItem);
      this.props.addToCart(auth.token, cartItem)
      .then(response => {
          console.log(response);
          console.log(this.props.cart);
        if(response){
            this.setState({
                redirectToReferrer: true
            });
            swal({
              title: "Done!",
              text: " Addtocart Success",
              icon: "success",
              timer: 1000,
              button: false
            })
         }
      })
      .catch(error => {
          console.log(error);
      });
  }

    

    render(){
      
      if(this.state.redirectToReferrer){
        return <Redirect to="/productcart" />
    }
   
        const { product } = this.state;

        let productDescription;
        let final_price =0;
        

        if(this.state.product){
          
            productDescription= 

         
            <div>
              
          <section className="page-header">
              <div className="container">
                <div className="col-lg-12 text-center">
                  <h2>Product Details</h2>
                </div>
              </div>
          </section>
           <div className="productpage">
            <div className="container">
            <div className="card p-details">
              <div className="container-fliud">
                <div className="row">
                <div className="preview col-md-4">
                          <img style={{  width: '100%' }} src={`${base_url}/productImages/`+ product.images} />
                 </div>
                  <div className="details col-md-4">
                    <h5 className="product-title">{product.producttitle}</h5>
                    <h5>Specification</h5>
                    <p className="product-description">{product.description}</p>
                    <h5 className="price">Price: <div className="price" id="price"><i className="fas fa-pound-sign">{product.discountprice==""?(final_price=product.price): (final_price=(parseInt(product.price)-parseInt(product.discountprice))) }</i>
                    
                    { product.discountprice!=""?
                        <span className="price-s" id="discountprice"><i className="fa fa-pound-sign" style={{textDecoration: 'line-through'}}>{product.price}</i></span>:""
                    }
                      </div>
                      </h5>
                    <div className="action">
                  <button  onClick={() => { this.addToCart(product._id, final_price, product.producttitle,product.images[0]) }}  className="thm-btn-dynamic-radius book-btn2"><i className="fa fa-shopping-cart">Add to cart</i></button> 
          
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
    </div>
       </div>

        }else{
            productDescription = <div>Product is loading....!</div>
        }
        
        return (
     <div>
         
         {productDescription}
     </div>
       
        );
    }

}
const mapStateToProps = state => {
  return {
      auth: state.auth,
      cart: state.cart
  }
}

const mapDispatchToProps = dispatch => {
  return {
      addToCart: (token, cartItem) => dispatch(cartActions.addToCart(token, cartItem)),
      getCartItems: (token, userId) => dispatch(cartActions.getCartItems(token, userId)),
      getToken: () => dispatch(authActions.getToken())
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(Productdetails);