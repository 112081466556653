import React from 'react';
import './style.css';
import { connect } from 'react-redux';

const CartPrice = props => {

    return (
        <div class="cart-totals">
        <div class="flex-w flex-t bor12 p-b-13">
        <div class="size-208">
          <span class="stext-110 cl2">
            Subtotal:
          </span>
          <span class="mtext-110 cl2">
          £{props.cart.totalAmount}
          </span>
        </div>

        <div class="size-209">
        
        </div>
      </div>
      <div class="flex-w flex-t bor12 p-t-15 p-b-30">
        <div class="size-208 w-full-ssm">
          <span class="stext-110 cl2">
            Shipping:
          </span>
          <span class="mtext-110 cl2">
          < p className="cartparagraph">There are no shipping methods available. Please double check your address, or contact us if you need any help. </p>
          </span>
          
        </div>
          </div>
          <div class="size-208">
          <span class="stext-110 cl2">
            Total Amount:
          </span>
          <span class="mtext-110 cl2">
          £{props.cart.totalAmount}
          </span>
        </div>
      
            
        </div>
    );


}

const mapStateToProps = state => {
    return {
        cart: state.cart
    }
}

export default connect(mapStateToProps, null)(CartPrice);