import Home from './Pages/Home.js';
import Header from './include/Header.js';
import Footer from './include/Footer.js';
import About from './Pages/About.js';
import Contact from './Pages/Contact.js';
import Shop from './Pages/Shop.js';
import Donation from './Pages/Donation.js';
import Gallery from './Pages/Gallery.js';
import Event from './Pages/Event.js';
import Sikhism from './Pages/Sikhism.js';
import News from './Pages/News.js';
import Privacypolicy from './Pages/Privacypolicy.js';
import Return from './Pages/Return.js';
import Termscondition from './Pages/Termscondition.js';
import Login from './Pages/Login.js';
import Register from './Pages/Register.js';
import Account from './Pages/Account.js';
import Prayer from './Pages/Prayer.js';
import Wedding from './Pages/Wedding.js';
import Funeral from './Pages/Funeral.js';
import Langer from './Pages/Langer.js';
import Services from './Pages/Services.js';
import Productdetails from './Pages/Productdetails.js';
import Eventdetails from './Pages/Eventdetails.js';
import Productcart from './Pages/Productcart.js';
import Bookcart from './Pages/Bookcart.js';
import Address from './Pages/Address.js';
import Paydonation1 from './Pages/Paydonation1.js';
import PlaceOrder from './PlaceOrder';
import {BrowserRouter as Router,Route,Switch} from 'react-router-dom';
import { Provider } from 'react-redux';
import PrivateRoute from './PrivateRoute';
import authReducers from './store/reducers/authReducers';
import { createStore, combineReducers, applyMiddleware } from 'redux';
import cartReducers from './store/reducers/cartReducers';
import thunk from 'redux-thunk';
import ThankYou from './ThankYou';
import BookThank from './BookThank';
import Orders from './Orders';
import Bookorder from './Bookorder';
import DonationThank from './DonationThank';
import Paydonation from './Paydonation';
import DonationAmount from './DonationAmount';
import Project from './Pages/Project.js';
import Charity from './Pages/Charity.js';
import Providers from './Pages/Provider.js';
import servicepolicy from './Pages/servicepolicy.js';


const rootReducers = combineReducers({
  auth: authReducers,
  cart: cartReducers,
});

const store = createStore(rootReducers, applyMiddleware(thunk));

window.store = store;
function App() {
  return (

    <div>
 <Provider store={store}>
      <Router>
       <Header/>
        <Switch>   
            <Route exact path="/" component={Home} />
            <Route  path="/about"  component={About} />
            <Route  path="/contact" component={Contact} />
            <Route  path="/shop" component={Shop} />
            <Route  path="/donation" component={Donation} />
            <Route  path="/gallery" component={Gallery} />
            <Route  path="/event" component={Event} />
            <Route  path="/sikhism" component={Sikhism} />
            <Route  path="/news" component={News} />
            <Route  path="/privacypolicy" component={Privacypolicy} />
            <Route  path="/returnpolicy" component={Return} />
            <Route  path="/terms&condtions" component={Termscondition} />
            <Route  path="/prayer" component={Prayer} />
            <Route  path="/wedding" component={Wedding} />
            <Route  path="/funeral" component={Funeral} />
            <Route  path="/langer" component={Langer} />
            <Route  path="/services" component={Services} />
            <Route  path="/service" component={servicepolicy} />
            <Route  path="/products/:productId" component={Productdetails} />
            <Route  path="/events/:eventId" component={Eventdetails} />
            <PrivateRoute path="/productcart" component={Productcart} />
            <PrivateRoute path="/place-order" component={PlaceOrder} />
            <PrivateRoute path="/thank-you" component={ThankYou} /> 
            <PrivateRoute path="/orders" component={Orders} />
            <PrivateRoute  path="/book" component={Bookcart} />
            <PrivateRoute  path="/book-thank" component={BookThank} />
            <PrivateRoute  path="/bookorder" component={Bookorder} />
            <PrivateRoute  path="/donationamount" component={DonationAmount} />
            <PrivateRoute path="/donationthank" component={DonationThank} />
            <Route  path="/charity" component={Charity} />
            <Route  path="/project" component={Project} />
            <Route  path="/provider" component={Providers} />
            <Route  path="/register" component={Register} />
            <Route path="/login" component={Login} />
            <Route  path="/paydonate" component={Paydonation} />
            <Route  path="/pay" component={Paydonation1} />
            <Route  path="/account" component={Account}  />
           <Route  path="/place-order" component={ Address}  />
        </Switch>     
         <Footer/>
      </Router>
      </Provider>
    </div>
            
 
  )
}

export default App;
