import React, { Component } from 'react'
import {Link} from 'react-router-dom';

export class Topheader extends Component {
    constructor() {
        super();
        this.state = {
            active: 'Home',
        

        }
        this._handleClick = this._handleClick.bind(this);
    }

    _handleClick(e) {
         this.setState({
            active: e.target.innerHTML
         });
    }
       
    render() {
        let homeStyle;
        let aboutStyle;
        let shopStyle;
        let donationStyle;
        let galleryStyle;
        let eventStyle;
        let contactStyle;


        if (this.state.active === 'Home') {
            homeStyle = { color: '#fff;', 
            borderRadius: '100px',
            background:'#f15c22',
            padding: '5px 30px' };
            aboutStyle = {};
            shopStyle={};
            donationStyle={};
            galleryStyle={};
            eventStyle={};
            contactStyle={}
           
           

        } else if(this.state.active === 'About') {
            homeStyle = {};
            aboutStyle = { color: '#fff;', 
            borderRadius: '100px',
            background:'#f15c22',
            padding: '5px 30px' };
            shopStyle={}
            donationStyle={};
            galleryStyle={};
            eventStyle={};
            contactStyle={}
          
        }
        else if(this.state.active === 'Shop') {
            homeStyle = {};
            aboutStyle = {};
            shopStyle={color: '#fff;', 
            borderRadius: '100px',
            background:'#f15c22',
            padding: '5px 30px'}
            donationStyle={};
            galleryStyle={};
            eventStyle={};
            contactStyle={}
        }
        else if(this.state.active === 'Donations') {
            homeStyle = {};
            aboutStyle = { };
            shopStyle={ };
            donationStyle={color: '#fff;', 
            borderRadius: '100px',
            background:'#f15c22',
            padding: '5px 30px'};
            galleryStyle={};
            eventStyle={};
            contactStyle={}
        }
        else if(this.state.active === 'Gallery') {
            homeStyle = {};
            aboutStyle = {};
            shopStyle={};
            donationStyle={};
            galleryStyle= {color: '#fff;', 
            borderRadius: '100px',
            background:'#f15c22',
            padding: '5px 30px'};
            eventStyle={};
            contactStyle={}
        }
        else if(this.state.active === 'Event') {
            homeStyle = {};
            aboutStyle = {};
            shopStyle={};
            donationStyle={};
            galleryStyle= {};
            eventStyle={color: '#fff;', 
            borderRadius: '100px',
            background:'#f15c22',
            padding: '5px 30px'};
            contactStyle={}
        }else{
            homeStyle = {};
            aboutStyle = {};
            shopStyle={};
            donationStyle={};
            galleryStyle= {};
            eventStyle={};
            contactStyle={color: '#fff;', 
            borderRadius: '100px',
            background:'#f15c22',
            padding: '5px 30px'}
        }

        return (
             <div>
 <div className="container">
                        <div className="row">
                        <div className="col-sm-12 col-md-12 col-lg-12 col-xl-2">
                            <div className="logo-box">
                            <a href="#" aria-label="logo image"><img src="../assets/images/logo-light.png" width={150} alt /></a>
                            <span className="fa fa-bars mobile-nav__toggler" />
                            </div>{/* /.logo-box */}
                        </div>{/* /.col-sm-12 */}
                        <div className="col-sm-12 col-md-12 col-lg-12 col-xl-10 d-none d-md-none d-lg-none d-xl-block">
                            <nav className="main-menu">
                        
                            <ul className="main-menu__list">
                                <li ClassName="active">
                                <Link style={homeStyle} onClick={this._handleClick} to="/">Home</Link>
                                </li>
                                <li className="dropdown">
                                <Link style={aboutStyle} onClick={this._handleClick}  to="/about">About</Link>
                
                                <ul>
                                    <li><Link  to="/sikhism">Sikhism</Link></li>
                                    <li><Link  to="/services">Services</Link></li>
                                    <li><Link  to="/event">Booking</Link></li>
                                    <li><Link  to="/donation">Donation</Link></li>
                                    <li><a href="/charity">Charity </a></li>
                                    <li><a href="/project">Projects</a></li>
                                    <li><a href="/provider">Providers</a></li>
                                </ul>
                                </li>
				
				{/*
                                <li  className="dropdown">
                                <Link style={shopStyle} onClick={this._handleClick}  to="/shop">Shop</Link>
                                </li>*/}

                                <li className="dropdown">
                                <Link  style={donationStyle} onClick={this._handleClick} to="/donation">Donations</Link>
                                </li>
                                <li className="dropdown">
                                <Link style={galleryStyle} onClick={this._handleClick} to="/gallery">Gallery</Link>
                                </li>
                                <li>
                                <Link  style={eventStyle} onClick={this._handleClick} to="/event">Event</Link>
                                </li>
                                <li>
                                <Link style={contactStyle} onClick={this._handleClick} to="/contact">Contact</Link>
                                </li>
                            </ul>
                            </nav>
                        </div>
                        </div>
                
                    </div>
                    <div className="stricky-header stricked-menu">
                    <div className="container">
                    <div className="logo-box">
                           <Link to="/"><img src="../assets/images/logo-light.png" width={101} alt /></Link>
                    </div>{/* /.logo-box */}
                    <div className="sticky-header__content" />
        
                          
                    <ul className="main-menu__list">
                                <li ClassName="active">
                                <Link style={homeStyle} onClick={this._handleClick} to="/">Home</Link>
                                </li>
                                <li className="dropdown">
                                <Link style={aboutStyle} onClick={this._handleClick}  to="/about">About</Link>
                
                                <ul>
                                <li><Link  to="/sikhism">Sikhism</Link></li>
                                    <li><Link  to="/services">Services</Link></li>
                                    <li><Link  to="/event">Booking</Link></li>
                                    <li><Link  to="/donation">Donation</Link></li>
                                    <li><a href="/charity">Charity </a></li>
                                    <li><a href="/project">Projects</a></li>
                                    <li><a href="/provider">Providers</a></li>
                                </ul>
                                </li>
                                
				{/*<li  className="dropdown">
                                <Link style={shopStyle} onClick={this._handleClick}  to="/shop">Shop</Link>
                                </li>*/}

                                <li className="dropdown">
                                <Link  style={donationStyle} onClick={this._handleClick} to="/donation">Donations</Link>
                                </li>
                                <li className="dropdown">
                                <Link style={galleryStyle} onClick={this._handleClick} to="/gallery">Gallery</Link>
                                </li>
                                <li>
                                <Link  style={eventStyle} onClick={this._handleClick} to="/event">Event</Link>
                                </li>
                                <li>
                                <Link style={contactStyle} onClick={this._handleClick} to="/contact">Contact</Link>
                                </li>
                            </ul>

                    </div>
                </div>
             </div>
              
                    
          
        )
    }
}

export default Topheader
