import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as authActions from '../store/actions/authActions';
import {Redirect} from 'react-router-dom';
import {Link} from 'react-router-dom';

import swal from 'sweetalert';

export class Account extends Component {

    constructor(props) {
        super(props);
        this.state = {
            redirect: false
        }
    
    }
    componentDidMount() {
        this.props.getToken();
    }
    logout = () => {
        localStorage.removeItem("auth");
        localStorage.clear();
        this.setState({ redirect: true });
        swal({
            title: "Done!",
            text: "Logout Success",
            icon: "success",
            timer: 1000,
            button: false
          })
    
    }
    
    render() {

        if (this.state.redirect) {
            return (
              
                <Redirect to={'/'} />
            )
        }
    
        return (
            <div>
                <section className="page-header">
                    {/* /.page-header__bg */}
                    <div className="container">
                    <div className="col-lg-12 text-center">
                        <h2>My Account</h2>
                    </div>
                    </div>{/* /.container */}
                </section>{/* /.page-header */}
                <section className="page-product" style={{marginTop: 30, marginBottom: 29}} >
                    <div className="container">
                    <div className="row">
                        <aside className="col-md-3">
                        <div className="card">
                            <div className="card-header">
                            <h3 className="card-title">Profile</h3>
                            </div>
                            <div className="card-body text-center item-user border-bottom">
                            <div className="profile-pic">
                                <div className="profile-pic-img">
                                <span className="bg-success dots" data-toggle="tooltip" data-placement="top" title data-original-title="online" />
                                <img src="./assets/images/events/user.jpg" className="brround" alt="user" />
                                </div>
                              </div>
                            </div>
                            <div className="item1-links dashboard mb-0">
                            <Link to= "/orders" className=" d-flex  border-bottom" style={{color:"rgb(87, 80, 78)" }}>
                                <span className="icon1 mr-3"><i className="far fa-shopping-cart" /></span> My Orders
                             </Link>
                            <Link to= "/bookorder"  className=" d-flex border-bottom" style={{color:"rgb(87, 80, 78)" }}>
                                <span className="icon1 mr-3"><i class="fa fa-address-book" ></i></span> My Event
                            </Link>
                            <Link to= "/donationamount"  className=" d-flex border-bottom" style={{color:"rgb(87, 80, 78)" }}>
                                <span className="icon1 mr-3"><i class="fa fa-id-badge"></i></span> My Donation
                            </Link>
                            <a href="/" className="d-flex" style={{color:"rgb(87, 80, 78)" }}>
                                <span className="icon1 mr-3" onClick={this.logout}><i className="fa fa-sign-out" /> Log Out </span>
                            </a>
                            </div>
                        </div>
                        {/* card.// */}
                        </aside>
                        <main className="col-md-9">
                        <div class="alert-message alert-message-success">
                           <h4>
                              Hiii...Welcome your Account
                           </h4>
                           <div className="Card">
                                <p className="CardText">Name:{this.props.auth.user.name}</p>
                        </div>
                        <div className="Card">
                                <p className="CardText">  Email: {this.props.auth.user.email}</p>
                        </div>
                       </div>    
                     
                        </main>
                    </div>
                    </div>
                </section>
                </div>
        )
    }
}
const mapStateToProps = state => {
    return {
        auth: state.auth,
    }
}
const mapDispatchToProps = dispatch => {
    return {
        getToken: () => dispatch(authActions.getToken())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Account);
