import React, { Component } from 'react'

export class Sikhism extends Component {
    render() {
        return (
            <div>
                    <section className="page-header">
                        {/* /.page-header__bg */}
                        <div className="container">
                        <div className="col-lg-12 text-center">
                            <h2>Origins of Sikhism</h2>
                        </div>
                        </div>{/* /.container */}
                    </section>{/* /.page-header */}
                    <section className="section video-card-one pt-60 pb-60" style={{background: '#f2ecec'}}>
                        <div className="container">
                        <div className="col-lg-12 col-xs-12 sp">
                            <p>In India, Hinduism has been a religion for centuries. All Vedas prove this point and later, when Buddhism and Jainism came, the Hindu religion carried on side by side with the population acting according to their likes. By the Seventh century A.D. Hinduism had once again become the main religion of the Country. By the Tenth century there was a regular inflow of the Muslims from the West. Soon they established their empire in the country and being the ruling class they started spreading their religion. Conversions, at times, took violent form and this lead to tension and mistrust between the Hindus and the Muslims. Over a period the two communities grew far apart and there was a class division between the rulers and the ruled.</p><br />
                            <p>The Northern part of the Country was always in turmoil with ever flowing Muslim invasions from the North-West. The constant Hindu-Muslim fights and mistrust gave rise to a movement of saintly persons by the Fifteenth century. They were both Hindus and Muslims and their effort was to bring the two together and make them realise that there was only one God though we referred to Him by different names.</p>
                        </div>{/* /.col-lg-5 */}
                        </div>{/* /.row */}
                    </section>{/* /.video-card-two */}
                    <section className="section video-card-one  pt-60 pb-60">
                        <div className="container">
                        <div className="col-lg-12 col-xs-12 sp">
                            <p>
                            One such person was born in the area of the present Pakistan and he was called Nanak. Born in a Hindu family he refused to under-go Hindu rituals and preached that there was but one God and that He was the only Truth. He took two persons, one each from the two communities, and undertook journeys through the length and breadth of the country including a trip to Ceylon, Tibet and in West to Mecca and Baghdad. Through out these travels Nanak preached that he was neither a Hindu nor a Muslim but a follower of the "Truth" and that his knowledge came from the "Word", meaning that the God could only be realised by meditation and self improvement. He denounced the rigid rituals of the Hindus and blind following of the systems of fasting, visiting religious places, and undergoing various forms of penance etc lead by the dogmatic Hindu priests. He denounced the Muslims for their persecution of the Hindus and also their oppressive means of converting people to their faith which was being led by their fundamentalist mullahas.
                            </p>
                        </div>{/* /.col-lg-5 */}
                        </div>{/* /.row */}
                    </section>{/* /.video-card-two */}
                    <section className="video-card-one  pt-60 pb-60" style={{background: '#f2ecec'}}>
                        <div className="container">
                        <div className="col-lg-12 col-xs-12 sp">
                            <p>
                            Nanak's preaching of the middle path and his denouncing the drawbacks of both the communities soon led to his having a large following. Nanak asked his disciples to earn their living by honest means and insisted they have a proper family life. His idea was that man could be living a normal life yet he could be a true follower of God by sticking to the word 'Truth'. No priests were required nor was there a need of a particular place or time for one to communicate with one's God. Nanak's followers were called 'Sikhs' a derivation of a Sanskrit word 'shishya' meaning a student-one who is learning. People from both the communities became his followers in large numbers.</p><br />
                            <p>Nanak became the first Master of the Sikhs and was followed by three others before the Mughal rulers at Delhi took notice of the fast growing sect. The Fifth Master, Arjun Dev, was put to death on instructions of the Mughal court. This resulted in the sect taking up arms to protect itself. The constant friction between Delhi court and the new community lead to the Tenth Master, Gobind Rai, converting the members to a warrior class and calling them 'Khalsas' the ' pure ones'-those who are pure in the heart and pure in their actions. This conversion lead the male members adding the word 'Singh' and the females 'Kaurs' to their names which means a loin and lioness respectively. The community has thereafter been always in the forefront of the battle against oppression on any kind.</p>
                        </div>{/* /.col-lg-5 */}
                        </div>{/* /.row */}
                    </section>{/* /.video-card-two */}

            </div>
        )
    }
}

export default Sikhism
