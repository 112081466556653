import React from 'react';
import QuantityControl from '../components/QuantityControl';

import './style.css';

const CartItem = props => {

    
    return (

    
        <tbody>
                   
                   <tr>
                     <td>
                      <div className="img-product">
                           <img  src={props.image}  alt className="mCS_img_loaded" />
                         </div>
                      
                     </td>
                     <td class="name-product">
                     {props.name}<br></br>
                       {/* <button className="thm-btn-dynamic-radius-cart" onClick={() => props.onRemoveCartItem()} >Remove</button> */}
                     </td>
                     <td class="price">
                     £{props.price}
                       </td>
                     <td className="product-count">
                     <QuantityControl
                                 productId={props.productId} 
                                 quantity={props.quantity}
                                 changeQuantity={props.changeQuantity}
                                 increaseQuantity={props.increaseQuantity}
                                 decreaseQuantity={props.decreaseQuantity}
                        />
                     </td>
                     <td>
                       <div className="total">
                       £{props.total}
                       </div>
                     </td>
                   </tr>
                   
                 </tbody>
    
         
 
    )
}

export default CartItem;