import React, { Component } from 'react'

export class About extends Component {
    render() {
        return (
            <div>
  <section className="page-header">
    {/* /.page-header__bg */}
    <div className="container">
      <div className="col-lg-12 text-center">
        <h2>About</h2>
      </div>
    </div>{/* /.container */}
  </section>{/* /.page-header */}
  <section className="about-one pt-120 pb-40">
    <div className="container">
      <div className="row">
        <div className="about-one__award">
          <img src="assets/images/shapes/about-bag-1-1.png" alt />
        </div>{/* /.about-one__award */}
        <div className="col-lg-6 col-md-6">
          <img src="./assets/images/backgrounds/img_11-copy.jpg" alt className="img-fluid" />
        </div>{/* /.col-lg-6 */}
        <div className="col-lg-6 col-md-6">
          <img src="./assets/images/backgrounds/img_11-copy.jpg" alt className="img-fluid"/>
        </div>{/* /.col-lg-6 */}
      </div>{/* /.row */}
    </div>{/* /.container */}
    <div className="container">
      <div className="team-about__top mt-60">
        <div className="row">
          <div className="col-md-4 col-lg-4">
            <div className="block-title">
              <p><img src="assets/images/shapes/heart-2-1.png" width={15} alt />Make a Difference</p>
              <h3>Let’s help them together.</h3>
            </div>{/* /.block-title */}
          </div>{/* /.col-md-12 col-lg-6 */}
          <div className="col-md-4 col-lg-4 ">
            <p className="team-about__top-text">Tincidunt elit magnis nulla facilisis sagittis is maecenas.
              Sapien
              nunced amet ultrices, dolores sit ipsum velit purus aliquet, massa fringilla leo orci ipsum
              dolor sit.</p>
          </div>{/* /.col-md-12 col-lg-6 */}
          <div className="col-md-4 col-lg-4">
            <p className="team-about__top-text">Sapien nunced amet ultrices, dolores sit ipsum velit purus
              aliquet,
              massa fringilla leo orci. Lorem ipsum dolor sit amet. consectetur adipisi cing elit dolo.
            </p>
          </div>{/* /.col-md-12 col-lg-6 */}
        </div>{/* /.row */}
      </div>{/* /.team-about__top */}
    </div>{/* /.container */}
  </section>{/* /.about-one */}

  <section className="team-about pb-120 pt-120" style={{backgroundImage: 'url(assets/images/team/team-map-1-1.png)'}}>
    <div className="container">
      <div className="team-about__top">
        <div className="row align-items-center">
          <div className="col-md-7 col-lg-7 ">
            <div className="block-title">
              <p><img src="./assets/images/shapes/imgp.png" width={15} alt />Our Volunteers</p>
              <h3>Meet those who help <br /> others in need.</h3>
            </div>{/* /.block-title */}
          </div>{/* /.col-md-12 col-lg-6 */}
          <div className="col-md-5 col-lg-5">
            <p className="team-about__top-text">Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Have you done google research which works all the time. </p>
          </div>{/* /.col-md-12 col-lg-6 */}
        </div>{/* /.row */}
      </div>{/* /.team-about__top */}
      <div className="team-4-col">
        <div className="team-card text-center content-bg-1">
          <div className="team-card__image">
            <img src="./assets/images/shapes/imgp.png" alt />
          </div>{/* /.team-card__image */}
          <div className="team-card__social">
            <a href="#" aria-label="twitter"><i className="fab fa-twitter" /></a>
            <a href="#" aria-label="facebook"><i className="fab fa-facebook-square" /></a>
            <a href="#" aria-label="pinterest"><i className="fab fa-pinterest-p" /></a>
            <a href="#" aria-label="instagram"><i className="fab fa-instagram" /></a>
          </div>{/* /.team-card__social */}
          <div className="team-card__content">
            <h3>Corey Dawson</h3>
            <p>Student</p>
          </div>{/* /.team-card__content */}
        </div>{/* /.team-card */}
        <div className="team-card text-center content-bg-2">
          <div className="team-card__image">
            <img src="./assets/images/shapes/imgp.png" alt />
          </div>{/* /.team-card__image */}
          <div className="team-card__social">
            <a href="#" aria-label="twitter"><i className="fab fa-twitter" /></a>
            <a href="#" aria-label="facebook"><i className="fab fa-facebook-square" /></a>
            <a href="#" aria-label="pinterest"><i className="fab fa-pinterest-p" /></a>
            <a href="#" aria-label="instagram"><i className="fab fa-instagram" /></a>
          </div>{/* /.team-card__social */}
          <div className="team-card__content">
            <h3>Zachary Pope</h3>
            <p>Student</p>
          </div>{/* /.team-card__content */}
        </div>{/* /.team-card */}
        <div className="team-card text-center content-bg-3">
          <div className="team-card__image">
            <img src="./assets/images/shapes/imgp.png" alt />
          </div>{/* /.team-card__image */}
          <div className="team-card__social">
            <a href="#" aria-label="twitter"><i className="fab fa-twitter" /></a>
            <a href="#" aria-label="facebook"><i className="fab fa-facebook-square" /></a>
            <a href="#" aria-label="pinterest"><i className="fab fa-pinterest-p" /></a>
            <a href="#" aria-label="instagram"><i className="fab fa-instagram" /></a>
          </div>{/* /.team-card__social */}
          <div className="team-card__content">
            <h3>Cole Erickson</h3>
            <p>Student</p>
          </div>{/* /.team-card__content */}
        </div>{/* /.team-card */}
        <div className="team-card text-center content-bg-4">
          <div className="team-card__image">
            <img src="./assets/images/shapes/imgp.png" alt />
          </div>{/* /.team-card__image */}
          <div className="team-card__social">
            <a href="#" aria-label="twitter"><i className="fab fa-twitter" /></a>
            <a href="#" aria-label="facebook"><i className="fab fa-facebook-square" /></a>
            <a href="#" aria-label="pinterest"><i className="fab fa-pinterest-p" /></a>
            <a href="#" aria-label="instagram"><i className="fab fa-instagram" /></a>
          </div>{/* /.team-card__social */}
          <div className="team-card__content">
            <h3>Violet Figueroa</h3>
            <p>Student</p>
          </div>{/* /.team-card__content */}
        </div>{/* /.team-card */}
      </div>{/* /.team-4-col */}
    </div>{/* /.container */}
  </section>{/* /.team-about */}
  <section className="video-card">
    <div className="video-card__bg" style={{backgroundImage: 'url(assets/images/backgrounds/page-header-1-1.jpg)'}} />
    {/* /.video-card__bg */}
    <div className="container text-center pt-120 pb-120">
      <p><img src="assets/images/shapes/heart-2-1.png" width={15} alt />Help Other People</p>
      <h3>Our fingerprints on the <br />
        lives we touch never fade.</h3>
      <div className="video-card__btn-block">
        <a href="#" className="thm-btn dynamic-radius">Start Donating</a>
        {/* /.thm-btn dynamic-radius */}
        <a href="https://www.youtube.com/watch?v=ExP56mAUE1s" className="video-popup video-card__btn"><i className="fa fa-play" /></a>{/* /.video-card__btn */}
      </div>{/* /.video-card__btn-block */}
    </div>{/* /.container */}
  </section>{/* /.video-card */}
  <section className="testimonials-one pt-120 pb-90" style={{backgroundImage: 'url(assets/images/shapes/testimonials-map-1-1.png)'}}>
    <div className="container">
      <div className="team-about__top">
        <div className="row align-items-center">
          <div className="col-md-12 col-lg-7">
            <div className="block-title">
              <p><img src="assets/images/shapes/heart-2-1.png" width={15} alt />Our Testimonials</p>
              <h3>What they are talking <br /> about azino.</h3>
            </div>{/* /.block-title */}
          </div>{/* /.col-md-12 col-lg-6 */}
          <div className="col-md-12 col-lg-5">
            <p className="team-about__top-text">Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Have you done google research which works all the time. </p>
          </div>{/* /.col-md-12 col-lg-6 */}
        </div>{/* /.row */}
      </div>{/* /.team-about__top */}
      <div className="row">
        <div className="col-lg-4 col-md-4 ">
          <div className="testimonials-one__single">
            <div className="testimonials-one__image">
              <img src="./assets/images/causes/client6.png" alt />
            </div>{/* /.testimonials-one__image */}
            <p>There are many variations of passages of lorsum available but the majority have suffered
              alteration in form, by injected not humour.</p>
            <h3>Alex Cooper</h3>
            <span>Customer</span>
          </div>{/* /.testimonials-one__single */}
        </div>{/* /.col-lg-4 */}
        <div className="col-lg-4 col-md-4">
          <div className="testimonials-one__single">
            <div className="testimonials-one__image">
              <img src="./assets/images/causes/client4.png" alt />
            </div>{/* /.testimonials-one__image */}
            <p>There are many variations of passages of lorsum available but the majority have suffered
              alteration in form, by injected not humour.</p>
            <h3>Sara Logan</h3>
            <span>Customer</span>
          </div>{/* /.testimonials-one__single */}
        </div>{/* /.col-lg-4 */}
        <div className="col-lg-4 col-md-4">
          <div className="testimonials-one__single">
            <div className="testimonials-one__image">
              <img src="./assets/images/causes/client2.png" alt />
            </div>{/* /.testimonials-one__image */}
            <p>There are many variations of passages of lorsum available but the majority have suffered
              alteration in form, by injected not humour.</p>
            <h3>Jacob Casey</h3>
            <span>Customer</span>
          </div>{/* /.testimonials-one__single */}
        </div>{/* /.col-lg-4 */}
      </div>{/* /.row */}
    </div>{/* /.container */}
  </section>{/* /.testimonials-one */}
</div>
        )
    }
}

export default About
