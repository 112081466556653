import React, { Component } from 'react';
import Header from '../include/Header';
import CartItem from '../CartItem';
import * as cartActions from '../store/actions/cartActions';
import * as authActions from '../store/actions/authActions';
import { connect } from 'react-redux';
import CartPrice from '../components/CartPrice';
import { base_url } from '../constants';

class Productcart extends Component{

    state = {
        cartItems: []
    }

    decreaseQuantity = (e, productId) => {
        this.updateCart(productId, -1);
    }

    increaseQuantity = (e, productId) => {
        this.updateCart(productId, 1);
    }

    updateCart = async (productId, quantity) => {
        try{
            const auth = this.props.auth;
            let product = this.state.cartItems.find(item => item.product === productId);
            product = {
                productId: product.product,
                quantity: parseInt(product.quantity) + parseInt(quantity),
                newQuantity: quantity,
                price: product.price,
                total: parseFloat(product.total) + parseFloat( product.price * quantity )
            }
            if(product.quantity <= 0){
                return;
            }
            const response = await this.props.updateCart(auth.token, auth.user.userId, product);
            if(response.ok == 1){
                const {cartItems} = this.state;
                this.setState({
                    cartItems: cartItems.map(item => item.product === productId ? 
                        {...item, quantity: item.quantity + quantity, total: item.total + (item.price * quantity)}: item)
                })
            }
        }catch(error){
            console.log(error);
        }
        
    }

    changeQuantity = (e, productId) => {

    }
 
    componentDidMount() {
        if(!this.props.auth.isAuthenticated){
            this.props.getToken()
            .then(result => {
                if(result){
                    const cartItems = this.props.getCartItems(this.props.auth.token, this.props.auth.user.userId);
                   return cartItems;
                }
                return [];
            })
            
            .then(cartItems => {
                if(cartItems.cart.length > 0){

                    console.log(this.props.cart)

                    this.setState({
                        cartItems: this.props.cart.cartItem
                    })
                }
            })
            .catch(error => {
                console.log(error);
            })
        }else{
            this.setState({
                cartItems: this.props.cart.cartItem
            })
        }
        //alert(JSON.stringify( this.state.cartItems));
    }

    render (){

        return (
       
                <div className="cart-wrap">
                  <div className="container">
                  <div className="row">
                      <div className="col-lg-8">
                        <div className="main-heading">Shopping Cart</div>
                        <div className="table-cart">
                  <table className="table-view">
                    <thead>
                      <tr>
                        <th >Product</th>
                        <th >Name</th>
                        <th >Price</th>
                        <th >Quantity</th>  
                        <th >Sub Total</th>
                      </tr>
                    </thead>
                
                    {
                                    this.state.cartItems.map(product => 
                                        <CartItem
                                            key={product.product}
                                            productId={product.product}
                                            name={product.name}
                                            image={`${base_url}/productImages/`+ product.image}
                                            price= {product.price}
                                            quantity={product.quantity}
                                            total={product.total}
                                            changeQuantity={this.changeQuantity}
                                            increaseQuantity={this.increaseQuantity}
                                            decreaseQuantity={this.decreaseQuantity}
                                           
                                            
                                    />
                                   
                                    )
                                }

                                
                  </table>
                </div>
             </div>
                        <div  class="col-lg-4">
                            <div class="cart-totals">
                            <h3>Cart Totals</h3>
                            < CartPrice/>
                            <div class="btn-cart-totals">
                                    <button class="checkout round-black-btn" onClick={() => this.props.history.push('/place-order')} >Place Order</button>
                            </div>
                                
                            </div>
                      </div> 
                </div>
            </div>
                         
   </div>
          
        );
    }
}

const mapStateToProps = state => {
    return {
        auth: state.auth,
        cart: state.cart
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getCartItems: (token, userId) => dispatch(cartActions.getCartItems(token, userId)),
        updateCart: (token, userId, product) => dispatch(cartActions.updateCart(token, userId, product)),
        clearCart: (token, productId) => dispatch(cartActions.clearCart(token,productId)),
        getToken: () => dispatch(authActions.getToken())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Productcart);