import React, { Component } from 'react'

export class Return extends Component {
    render() {
        return (
            <div>
  <section className="page-header">
    {/* /.page-header__bg */}
    <div className="container">
      <div className="col-lg-12 text-center">
        <h2>Return Policy</h2>
      </div>
    </div>{/* /.container */}
  </section>{/* /.page-header */}
  <section className="video-card-one pt-60 pb-60" style={{background: '#f2ecec'}}>
    <div className="container">
      <div className="col-lg-12 col-xs-12">
        <h3>Please read carefully</h3>
        <p>Gurdwara Baba Vadbhag Singh Ji are collaborating with Printful to print and deliver the items from our store. We design the products for the store and Printful, makes and delivers them. Our “Return Policy” is based on Printful’s “Return Policy”. We are unfortunately unable to provide returns, refunds or exchanges, unless a product is damaged (as reviewed by Printful). </p><br />
        <p> We only replace (exchange) items if they are defective or damaged. To be eligible for an exchange of a damaged good, clear pictures of the item must be taken and emailed to info@gurdwarabvs.org and will be reviewed by Printful. We will send a return merchandise authorization (RMA) number to be exchanged with the same item. </p><br />
        <p>ALL SALES ARE FINAL as each order placed is custom made for every single customer after the order is received. We do not accept returns or replacements in the case where the customer has buyer’s remorse, needs a different color, or a smaller or larger size. (You can view the sizing chart on each of the product pages and please don’t hesitate to contact us with any further questions before ordering.)</p><br />
        <p>
          Once you have clicked on the “complete order” button, unfortunately it is not possible to edit or cancel your order. However, if you need to change some parameters, like addresses, etc., please contact us via email (info@gurdwarabvs.org) as soon as possible. We are not bound to make such modifications in your order, but we will do our best on a case-by-case basis.
        </p>
      </div>{/* /.col-lg-5 */}
    </div>{/* /.row */}
  </section>{/* /.video-card-two */}
  <section className="video-card-one pt-60 pb-60">
    <div className="container">
      <div className="col-lg-12 col-xs-12">
        <h3>Shipping Policy</h3>
        <h3>Estimated shipping delivery times</h3>
        <p>USA: 3-5 business days<br />
          Canada: 5-10 business days<br />
          World: 10-20 business days<br />
          Your order will be sent out on average within 3 days of ordering. </p>
        <h3 style={{color: '#f15c22'}}>International shipping</h3>
        <p>International shipments may incur customs fees. For each country the customs policy is different, and the fee is usually based on a variety of factors like weight, value, and size. We do not take responsibility for customs fees. </p><br />
        <p>The customer pays all shipping, insurance and customs fees, and assumes responsibility for any lost shipments to countries that don’t allow us to insure shipments within their borders.</p>
        <h3>Reason for return</h3>
        <p>Wrong Address – If you provide an address that is considered insufficient by the courier, the shipment will be returned to the Printful headquarters. You will be liable for reshipment costs once we have confirmed an updated address with you. </p><br />
        <p>Unclaimed – Shipments that go unclaimed are returned to the Printful headquarters and you will be liable for the cost of a reshipment to you.</p>
        <h3 style={{color: '#f15c22'}}>
          Damaged items
        </h3>
        <p>
          The last thing we want is for you to be stuck with a damaged item. If it arrives to you damaged, then please get in touch with us within a week’s time!
        </p><br />
        <p>Along with Printful, we will review replacement/return requests only:</p>
        <br /><p>(a) if a product is damaged or there is a print error</p><br />
        <p>(b) we receive an email with clear pictures of the damaged item to info@gurdwarabvs.org</p><br />
        <p>We only replace items if they are defective or damaged. Printful do not offer any refund orders for buyer’s remorse, or for size exchanges as each order is custom made for each customer. Therefore, a new order would need to be placed for an updated size.If you need to exchange a defective or damaged item for the same item, send us an email at info@gurdwarabvs.org with pictures to get the return merchandise authorization (RMA) number. </p>
        <br /><p>Please note that you will be responsible for paying for your own shipping costs for returning your item. Shipping costs are non-refundable. If you receive a refund, the cost of return shipping will be deducted from your refund. </p>
      </div>{/* /.col-lg-5 */}
    </div>{/* /.row */}
  </section>{/* /.video-card-two */}
  <section className="video-card-one pt-60 pb-60" style={{background: '#f2ecec'}}>
    <div className="container">
      <div className="col-lg-12 col-xs-12">
        <h3>Lost shipment</h3>
        <p>It is the customer’s responsibility to file any claim with a carrier for a lost shipment if carrier tracking indicates that the product was delivered. Unfortunately, in such case Printful will not make any refunds and will not resend the Product.</p>
        <h3 style={{color: '#f15c22'}}>Order never arrived</h3>
        <p>If your order didn’t end up arriving, be sure to let us know!</p><br />
        <p>First check your shipping confirmation and check that you had entered the correct address. If the address was incorrect, though we’d be happy to send you another order to the correct address, it will have to be at your cost.
          If the shipping address was correct, get in touch with us at info@gurdwarabvs.org noting your order number. We’ll see what can be done. </p>
        <h3>Sale items</h3>
        <p>Only regular priced items may be refunded, unfortunately sale items cannot be refunded.</p>
      </div>{/* /.col-lg-5 */}
    </div>{/* /.row */}
  </section>{/* /.video-card-two */}
</div>
        )
    }
}

export default Return
