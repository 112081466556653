import React, { Component } from 'react'
import SectionsContainer from '../SectionsContainer.js';
import {Link} from 'react-router-dom';
import { base_url } from '../constants';
import axios from 'axios';
import swal from 'sweetalert';


export class Home extends Component {
  constructor(props) {
    super(props)
    this.state = {
      current: 0,
      products: [],
      gallery: [],
     
   
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChangeEmail = this.handleChangeEmail.bind(this);
  }
  handleChangeEmail(e) {
    this.setState({ email: e.target.value });

  }
  handleSubmit(event) {
    event.preventDefault();
      const data = {
             email : this.state.email,

      }
      axios.post(`${base_url}/email/createmail`,data).
      then(()=>{ 
        this.setState({ redirect: true });
        swal({
          title: "Done!",
          text: "Email Success",
          icon: "success",
          timer: 1000,
          button: false,
        
      })
      window.location="/";
     })

  }
componentDidMount () {
  fetch(`${base_url}/gallery/gallerysix`)
  .then((response) => response.json())
  .then(galleryList => {
      this.setState({ gallery: galleryList });
  });
  fetch(`${base_url}/product/fetchshop`)
  .then((response) => response.json())
  .then(productList => {
      this.setState({ products: productList });
  });

}
formatDate = (date) => {
  let d = new Date(date);
  return `${d.getDate()}-${d.getMonth() + 1}-${d.getFullYear()}`;
}

handleChangeEmail(e) {
  this.setState({ email: e.target.value });

}
  render() {
    const options = {
      sectionClassName: 'section',
      anchors: ['1', '2', '3', '4','5','6','7','8','9'],
      scrollBar: false,
       arrowNavigation: true,
      scrollCallback: (states) => this.setState({current: states.activeSection})
  };
  let final_price =0;
    return (
    
      <div >
    <SectionsContainer {...options}>
        <section className="main-slider smooth">
              <div className="swiper-container thm-swiper__slider" data-swiper-options="{&quot;slidesPerView&quot;: 1, &quot;loop&quot;: true,
                &quot;effect&quot;: &quot;fade&quot;,
                &quot;pagination&quot;: {
              &quot;el&quot;: &quot;#main-slider-pagination&quot;,
              &quot;type&quot;: &quot;bullets&quot;,
              &quot;clickable&quot;: true
            },
                &quot;autoplay&quot;: {
              &quot;delay&quot;: 5000
                }}">
                <div className="swiper-wrapper">
		<div className="swiper-slide">
                    <div className="image-layer" style={{backgroundImage: 'url(assets/images/main-slider/img4.jpg)'}} />
                    <div className="container">
                      <div className="row justify-content-end">
                        <div className="col-xl-8 col-lg-12 text-right">
                          <p>Faith In God</p>
                          <h2>Those who have on <br /> loved are those that <br /> have found God</h2>
                        </div>{/* /.col-lg-7 text-right */}
                      </div>{/* /.row */}
                    </div>{/* /.container */}
                  </div>{/* /.swiper-slide */}

                  <div className="swiper-slide">
                    <div className="image-layer" style={{backgroundImage: 'url(assets/images/main-slider/guru1.jpg)'}} />
                    <div className="container">
                      <div className="row justify-content-end">
                        <div className="col-xl-7 col-lg-12 text-right">
                          <p>Faith In God</p>
                          <h2>He who has <br />no faith in himself <br /> can never have faith in God.</h2>
                        </div>{/* /.col-lg-7 text-right */}
                      </div>{/* /.row */}
                    </div>{/* /.container */}
                  </div>{/* /.swiper-slide */}
                  <div className="swiper-slide">
                    <div className="image-layer" style={{backgroundImage: 'url(assets/images/main-slider/slider-1-2.jpg)'}} />
                    {/* /.image-layer */}
                    <div className="container">
                      <div className="row justify-content-end">
                        <div className="col-xl-8 col-lg-12 text-right">
                          <p>Faith In God</p>
                          <h2>Those who have on <br /> loved are those that <br /> have found God</h2>
                        </div>
                      </div>{/* /.row */}
                    </div>{/* /.container */}
                  </div>{/* /.swiper-slide */}
                  <div className="swiper-slide">
                    <div className="image-layer" style={{backgroundImage: 'url(assets/images/main-slider/img4.jpg)'}} />
                    <div className="container">
                      <div className="row justify-content-end">
                        <div className="col-xl-8 col-lg-12 text-right">
                          <p>Faith In God</p>
                          <h2>Those who have on <br /> loved are those that <br /> have found God</h2>
                        </div>{/* /.col-lg-7 text-right */}
                      </div>{/* /.row */}
                    </div>{/* /.container */}
                  </div>{/* /.swiper-slide */}
                </div>{/* /.swiper-wrapper */}
                <div className="swiper-pagination" id="main-slider-pagination" />
              </div>{/* /.swiper-container thm-swiper__slider */}
            </section>{/* /.main-slider */}
          
  <section className="service-one smooth pt-110 pb-110">
              <div className="container">
                <div className="block-title">
                  <h3 style={{color: '#00aeef'}}>Services that are available</h3>
                </div>{/* /.block-title */}
                <div className="row">
                  <div className="col-md-6 col-lg-3">
                    <div className="service-one__box">
                      <img src="assets/images/shapes/prayer-icon.png" />
                      <h3><Link to="/prayer" target="_blank">Prayer</Link></h3>
                      <p>Read more on the types of prayers we offer along with information on how to make a booking.</p>
                    </div>{/* /.service-one__box */}
                  </div>{/* /.col-md-6 col-lg-3 */}
                  <div className="col-md-6 col-lg-3">
                    <div className="service-one__box">
                      <img src="assets/images/shapes/langar-icon.png" />
                      <h3><Link to="/langer" target="_blank">Langar</Link></h3>
                      <p>If you're interested in booking a langar at the Gurdwara, please get in touch and we will advise you further.</p>
                    </div>{/* /.service-one__box */}
                  </div>{/* /.col-md-6 col-lg-3 */}
                  <div className="col-md-6 col-lg-3">
                    <div className="service-one__box ">
                      <img src="assets/images/shapes/funeral-icon.png" />
                      <h3><Link to="/funeral" target="_blank">Funeral</Link></h3>
                      <p>We guide you through the process and explain what you need to do following a bereavement.</p>
                    </div>{/* /.service-one__box */}
                  </div>{/* /.col-md-6 col-lg-3 */}
                  <div className="col-md-6 col-lg-3">
                    <div className="service-one__box">
                      <img src="assets/images/shapes/wedding-icon.png" />
                      <h3><Link to="/wedding">Wedding</Link></h3>
                      <p>Make your special day memorable with a wedding at Gurdwara Baba Vadbhag Singh Ji.</p>
                    </div>{/* /.service-one__box */}
                  </div>{/* /.col-md-6 col-lg-3 */}
                </div>{/* /.row */}
              </div>{/* /.container */}
              <div className="call-to-action-two" style={{backgroundImage: 'url(assets/images/backgrounds/hindu.jpg)'}}>
                <div className="call-to-action-two__bg"  >
                </div>
                <div className="container pt-20">
                  <div className="row align-items-center">
                    <div className="col-lg-12">
                      <div className="block-title hop">
                        <h3>The best place for worship for Hindu Сommunity</h3>
                      </div>{/* /.block-title */}
                    </div>{/* /.col-lg-6 */}
                  </div>{/* /.row */}
                </div>{/* /.container */}
              </div>{/* /.call-to-action-two */}
            </section>{/* /.service-one */}

     <section className="video-card-two smooth pt-110 pb-110" style={{background: '#f2ecec'}}>
          <div className="container" data-wow-duration="1500ms">
              <div className="row">
                <div className="col-lg-6 col-xs-12">
                  <h3 style={{color: '#f15c22'}}>Donations</h3>
                  <p>Gurdwara Baba Vadbhag Singh Ji is a non-political, non-profit making, religious charitable organisation. We are reliant upon donations to keep the Gurdwara Sahib running smoothly so that we can provide our congregation the best possible experience upon each visit. If you would like to make a donation or set up a standing order,you can find out more by clicking on the link below.</p>
                  <Link to="/donation" className="thm-btn-dynamic-radius book-btn" style={{background: '#00aeef'}}>Click Here
                  </Link>{/* /.thm-btn dynamic-radius */}
                </div>{/* /.col-lg-5 */}
                <div className="col-lg-6 col-xs-12">
                  <div className="video-card-two__box">
                    <img src="assets/images/causes/temple.jpg" alt />
                    {/* /.video-card-two__box-btn */}
                  </div>{/* /.video-card-two__box */}
                </div>{/* /.col-lg-3 */}
              </div>{/* /.row */}
            </div>{/* /.container */}
          </section>{/* /.video-card-two */}
              <section className="smooth video-card-two pt-110 pb-110">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 col-xs-12">
                <div className="video-card-two__box">
                  <a href="https://www.youtube.com/watch?v=ExP56mAUE1s" className="video-card-two__box-btn video-popup" target="_blank"><i className="fa fa-play-circle" /></a>
                  <img src="assets/images/resources/img5.jpg" alt />
                  {/* /.video-card-two__box-btn */}
                </div>{/* /.video-card-two__box */}
              </div>{/* /.col-lg-3 */}
              <div className="col-lg-6 col-xs-12">
                <h3>Lorem Ipsum is simply dummy</h3>
                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Have you done google
                  research which works all the time,Lorem Ipsum is simply dummy text of the printing and typesetting industry. Have you done google
                  research which works all the time.</p>
                <Link to="/event" className="thm-btn-dynamic-radius text-center book-btn" target="_blank">Start
                  Booking</Link>
              </div>
            </div>{/* /.row */}
          </div>{/* /.container */}
        </section>{/* /.video-card-two */}
                <section className="gallery-page smooth pt-110 pb-110" >
            <div className="block-title text-center">
              <h3 style={{color: '#00aeef'}}>Gallery</h3>
            </div>{/* /.block-title */}
            <div className="container">
              <div className="gallery-3-col">
              {this.state.gallery.map((g) => (
                 <div className="gallery-card">
                     <img src={`${base_url}/galleryUpload/`+ g.images} className="img-fluid" />
                        <div className="gallery-content">
                            <a href={`${base_url}/galleryUpload/`+ g.images} className="img-popup" aria-label="open image" target="_blank"><i className="fal fa-plus" /></a>
                          </div>
                         </div> 
                     ))} 
              </div>{/* /.gallery-3-col */}
            </div>{/* /.container */}
          </section>{/* /.gallery-page */}
              <section className="smooth team-about pt-110 pb-110" style={{backgroundImage: 'url(assets/images/team/team-map-1-1.png)'}}>
          <div className="container">
            <div className="team-about__top">
              <div className="row align-items-center">
                <div className="col-md-12 col-lg-12">
                  <div className="block-title" style={{textAlign: 'center'}}>
                    <h3>Some of our products</h3>
                  </div>
                  {/* /.block-title */}
                </div>
                {/* /.col-md-12 col-lg-6 */}
              </div>
              {/* /.row */}
            </div>
            {/* /.team-about__top */}
            <div className="event-grid">
            {this.state.products.map((product) => (
              <div className="event-card">
                <div className="event-card-inner">
                  <div className="event-card-image">
                    <div className="event-card-image-inner">
                    <Link to={`/products/${product._id}`}><img style={{ width: '150px', height: '150px'}} src={`${base_url}/productImages/`+ product.images} /></Link>
                      <span>NEW</span>
                    </div>
                  </div>
                  <div className="event-card-content">
                  <h3> <Link to={`/products/${product._id}`} target="_blank">{product.producttitle}</Link></h3>
                  <h4 style={{color:'#f15c22'}}>£{product.discountprice==""?(final_price=product.price): (final_price=(parseInt(product.price)-parseInt(product.discountprice))) }</h4>      
                  { product.discountprice!=""?
                        <h4><i className="fa fa-pound-sign" style={{textDecoration: 'line-through'}}>{product.price}</i></h4>:""
                    }
                 <Link to={`/products/${product._id}`} target="_blank" className="thm-btn-dynamic-radius-viewdeatils " data-target="#myModal" >View details</Link>
                   </div> 
                </div>
              </div>
                
                ))}
            </div>
          </div>
        </section>

 <section className="client-carousel smooth pt-110 pb-110 client-carousel__has-border-top">
  <div className="container">
    <div className="thm-swiper__slider swiper-container swiper-container-initialized swiper-container-horizontal" data-swiper-options="{&quot;spaceBetween&quot;: 100, &quot;slidesPerView&quot;: 5, &quot;autoplay&quot;: { &quot;delay&quot;: 2000 }, &quot;breakpoints&quot;: {
      &quot;0&quot;: {
          &quot;spaceBetween&quot;: 30,
          &quot;slidesPerView&quot;: 2
      },
      &quot;375&quot;: {
          &quot;spaceBetween&quot;: 30,
          &quot;slidesPerView&quot;: 2
      },
      &quot;575&quot;: {
          &quot;spaceBetween&quot;: 30,
          &quot;slidesPerView&quot;: 3
      },
      &quot;767&quot;: {
          &quot;spaceBetween&quot;: 50,
          &quot;slidesPerView&quot;: 4
      },
      &quot;991&quot;: {
          &quot;spaceBetween&quot;: 50,
          &quot;slidesPerView&quot;: 5
      },
      &quot;1199&quot;: {
          &quot;spaceBetween&quot;: 100,
          &quot;slidesPerView&quot;: 5
      }
  }}">
      <div className="block-title text-center ">
        <h3 style={{color: '#00aeef'}}>Our Community Partners</h3>
        <p style={{marginLeft: 120, color: '#777575', padding: 20}}>Gurdwara Baba Vadbhag Singh Ji are proud to be working closely with the following local businesses and community teams to deliver our ever expanding charitable services.</p>
      </div>{/* /.block-title */}
      <div className="swiper-wrapper" style={{transform: 'translate3d(-1270px, 0px, 0px)', transitionDuration: '0ms'}}>
        <div className="swiper-slide">
          <img src="./assets/images/causes/client2.png" alt />
        </div>{/* /.swiper-slide */}
        <div className="swiper-slide">
          <img src="./assets/images/causes/client3.png" alt />
        </div>{/* /.swiper-slide */}
        <div className="swiper-slide">
          <img src="./assets/images/causes/client4.png" alt />
        </div>{/* /.swiper-slide */}
        <div className="swiper-slide">
          <img src="./assets/images/causes/client6.png" alt />
        </div>{/* /.swiper-slide */}
        <div className="swiper-slide">
          <img src="./assets/images/causes/client2.png" alt />
        </div>{/* /.swiper-slide */}
        <div className="swiper-slide">
          <img src="./assets/images/causes/client3.png" alt />
        </div>{/* /.swiper-slide */}
        <div className="swiper-slide">
          <img src="./assets/images/causes/client4.png" alt />
        </div>{/* /.swiper-slide */}
        <div className="swiper-slide">
          <img src="./assets/images/causes/client6.png" alt />
        </div>{/* /.swiper-slide */}
      </div>{/* /.swiper-wrapper */}
      <span className="swiper-notification" aria-live="assertive" aria-atomic="true" /></div>{/* /.thm-swiper__slider */}
    <div className="col-sm-12">
      <div className="content">
        <h5 className="footer-widget__title" style={{padding: 20, fontSize: 30, color: '#00aeef'}}>Newsletter</h5>
        <p>Join our mailing list for the most up-to-date information.</p>
        <form  onSubmit={this.handleSubmit}>
          <div className="input-group">
            <input type="email" className="form-control"  name="email"   value={this.state.email} onChange={this.handleChangeEmail} placeholder="Enter your email" />
            <span className="input-group-btn">
              <button className="btn" type="submit">Subscribe Now</button>
            </span>
          </div>
        </form>
      </div>
    </div>
  </div>
  
</section>
<section className="site-footer smooth pt-110 pb-110">
  <div className="main-footer">
    <div className="container">
      <div className="row">
        <div className="col-lg-3 col-md-6 col-sm-12" style={{marginTop: 39}}>
          <div className="footer-widget footer-widget__about">
            <Link to="/" aria-label="logo image">
              <img src="assets/images/logo-light.png" className="footer-widget__logo" width={101} alt />
            </Link>
            <p>Lorem ipsum dolor sit amet consect etur adi  Find Us</p>
            <ul className="list-unstyled footer-widget__contact">
            <li><a href="/"><i className="azino-icon-telephone" />0247 664 4401 </a></li>
                  <li><a href="/"><i className="azino-icon-email" />info@gurdwarabvs.org</a></li>

                  <li><a href="/"><i className="azino-icon-pin" />6 Bayton Road, Exhall, Coventry, CV7 9EJ</a></li>
                  <li><a href="/"><i className="fa fa-calendar" />MON - THURS: 10:00 - 8:00</a></li>
                  <li><a href="/"><i className="fa fa-clock" />FRI - SUN: 06:00 - 19:00</a></li>
            </ul>
          </div>
        </div>
        {/* /.col-lg-3 col-md-6 col-sm-12 */}
        <div className="col-lg-3 col-md-6 col-sm-12">
              <div className="footer-widget footer-widget__link">
                <h3 className="footer-widget__title"  style={{marginTop: 39}}>Site Links</h3>
                {/* /.footer-widget__title */}
                <ul className="list-unstyled footer-widget__link-list">
                  <li><Link to="/about">About</Link></li>
                  <li><Link to="/event">Event</Link></li>
                  <li><Link to="/news">News</Link></li>
                  <li><Link to="/shop">Shop</Link></li>
                  <li><Link to="/contact">Contact</Link></li>
                </ul>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12">
              <div className="footer-widget footer-widget__newsletter">
                <h3 className="footer-widget__title"  style={{marginTop: 39}}>Policies</h3>
                <ul className="list-unstyled footer-widget__link-list">
                  <li><Link to="/privacypolicy">Privacy Policy</Link></li>
                  <li><a href="/service">Service Policy</a></li>
                  <li><Link to="/returnpolicy">Return Policy</Link></li>
                  <li><Link to="/terms&condtions">Terms &amp; Conditions</Link></li>
                  <li><a href="assets/images/causes/gurudwara.pdf">Standing Order</a></li>
                </ul>
                <div className="mc-form__response" />
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12" style={{marginTop: 39}}>
          <div className="footer-widget footer-widget__blog">
           
        
         
          </div>
          {/* /.footer-widget footer-widget__blog */}
        </div>
      </div>
    </div>
  </div>
  <div className="footer-bottom">
    <div className="container">
      <div className="col-lg-12 col-md-12 col-sm-12 text-center">
        <div className="footer-social">
          <a href="#" aria-label="twitter"><i className="fab fa-twitter" /></a>
          <a href="#" aria-label="facebook"><i className="fab fa-facebook-square" /></a>
          <a href="#" aria-label="pinterest"><i className="fab fa-pinterest-p" /></a>
          <a href="#" aria-label="instagram"><i className="fab fa-instagram" /></a>
        </div>
        <p>Copyright © 2020 Gurdwara Baba Vadbhag Singh Ji | Registered Company No: 06553894 | Registered Charity No: 1139319</p>
      </div>
      {/* /.footer-social */}
    </div>
    {/* /.container */}
  </div>
  {/* /.footer-bottom */}
</section>
<section class="google-map__home smooth pt-110 pb-110">
            <iframe  title="template google map" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2430.842257139089!2d-1.4841182841550795!3d52.46388417980364!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x48774c20d0ac8c1d%3A0x407373e2fa668a95!2sGurdwara%20Dhan%20Dhan%20Baba%20Vadbhag%20Singh%20Ji%20Bedworth!5e0!3m2!1sen!2sin!4v1603795567235!5m2!1sen!2sin" class="map__home" ></iframe>
</section>{/* <!-- /.google-map --> */}
                 
       </SectionsContainer>
            
</div>

    )
  }
}

export default Home
