import React, { Component } from 'react'

export class Langer extends Component {
    render() {
        return (
            <div>
                  <section className="page-header">
    {/* /.page-header__bg */}
    <div className="container">
      <div className="col-lg-12 text-center">
        <h2>Sikh Langer</h2>
      </div>
      {/* /.thm-breadcrumb list-unstyled */}
    </div>
    {/* /.container */}
  </section>
  <div className=" login-section ptb-100">
  <div className="container">
    <div className="row">
      <div className=" col-lg-6">
        <div className=" login-form left-form">
          <div className="block-title text-center">
            <h3>Langer Enquiry</h3>
          </div>                       
          <form >
            <div className="row">
               <div className="col-lg-12">
                <div className="form-group">
                  <input type="text"  name="email"  className="form-control" placeholder="Name" />  
                </div>
              </div>
         
              <div className="col-lg-12">
              <div className="form-group">
              <input  type="text"  className="form-control"  placeholder="Number" />
             </div>
              </div>
              <div className="col-lg-12">
              <div className="form-group">
              <input  type="text"  className="form-control"  placeholder="Email" />
             </div>
              </div>
              <div className="col-lg-12">
              <div className="form-group">
              <textarea name="message" className="form-control" placeholder="Message" />
             </div>
              </div>
              <div className="col-lg-12">
                <div className="send-btn">
                  <button type="submit" className="default-btn-one">Submit</button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
      <div className="col-lg-6">
      
      <div className="video-card-two__box">
                    <img src="assets/images/causes/g.jpg" alt />
                    {/* /.video-card-two__box-btn */}
      </div>{/* /.video-card-two__box */}
      </div>
    </div>
  </div>
</div>

            </div>
        )
    }
}

export default Langer
